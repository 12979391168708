import React, { createContext, useState, useContext, useEffect } from "react";
import { login } from "./utils";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [authed, setAuthed] = useState(false);
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        const storedAuthed = localStorage.getItem("authed") === "true";
        const storedUserId = localStorage.getItem("userId");
        const storeOfficeId = localStorage.getItem("officeId");
        const token = localStorage.getItem("jwt_token");


        if (storedAuthed && storedUserId && token) {
            setAuthed(true);

            if(storedUserId){
                setUserId(storedUserId);
            }
            else{
                setUserId(storeOfficeId);
            }
        }
    }, []);

    const handleLogIn = async (credentials, selectedRole) => {
        try {
            const userData = await login(credentials, selectedRole);
            const {user_id, first_name, last_name, email, office_id, agent_role, role, name, jwt_token} = userData;

            localStorage.setItem("jwt_token", jwt_token);
            localStorage.setItem("authed", "true");
            setAuthed(true);

            // Conditionally saving info for local storage
            if (agent_role) {
                // Agent/Manager/ADMIN
                setUserId(user_id);
                // localStorage.setItem("userId", user_id);
                localStorage.setItem("first_name", first_name);
                localStorage.setItem("last_name", last_name);
                localStorage.setItem("email", email);
                localStorage.setItem("full_name", `${first_name} ${last_name}`);
                localStorage.setItem("agent_role", agent_role);
            } else if (role === "OFFICE") {
                // Office
                setUserId(office_id);
                localStorage.setItem("full_name", name + " Office");
                localStorage.setItem("email", email);
                localStorage.setItem("officeId", office_id);
                localStorage.setItem("agent_role", role);
                localStorage.setItem("userId", office_id);
                console.log(office_id)

            }

            return userData;
        } catch (error) {
            throw error;
        }
    };

    const handleLogOut = () => {
        setAuthed(false);
        setUserId(null);
        // Remove all user-related info from localStorage
        localStorage.clear()
    };

    return (
        <AuthContext.Provider value={{authed, userId, handleLogIn, handleLogOut}}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);