import React, {useCallback, useEffect, useState, useRef} from 'react';
import {Button, DatePicker, message, Table, Tag, Typography, notification} from "antd";
import useTableSearch from "../Utils/useTableSearch";
import {useNavigate, useParams} from "react-router-dom";
import {
    dealNavigate,
    getLiveTransactions,
    getDaysStagnant,
    calculateTimePeriod,
    getLiveTransactionFilter, dateFormat
} from "../Utils/utils";
import {OfficeSelect} from "../Utils/SearchUtil";
import moment from "moment/moment";


const {Title} = Typography
const {CheckableTag} = Tag;
const {RangePicker} = DatePicker;


export const typeColorMapping = {
    CLOSED: 'black',
    PRELIMINARY: 'green',
    CONTINGENT: 'red',
    ACTIVE: "darkgreen",
    PENDING: "grey"
};

export const clientTypeFilters = [
    {text: 'SELLER', value: 'SELLER'},
    {text: 'BUYER', value: 'BUYER'},
    {text: 'LANDLORD', value: 'LANDLORD'},
    {text: 'RENTER', value: 'RENTER'},
    {text: 'INVESTOR', value: 'INVESTOR'},
]

export const statusFilter = [
    {text: 'CLOSED', value: 'CLOSED'},
    {text: 'PRELIMINARY', value: 'PRELIMINARY'},
    {text: 'CONTINGENT', value: 'CONTINGENT'},
    {text: 'PENDING', value: 'PENDING'},
]

// RENTER, BUYER, INVESTOR → "AOI"
// SELLER, LANDLORD → "SOP"
export const checkDealType = (clientType) => {
    const aois = ["BUYER", "INVESTOR", "RENTER"];
    return aois.includes(clientType) ? "AOI" : "SOP";
};

const tagsData = ['Last 24 Hours', 'Last Week', 'Last Month', 'All Transactions'];

function LiveTransactionTable({agentId, agentRole}) {
    const {id, role} = useParams();
    // Set agent id
    agentId = agentId ? agentId : id
    agentRole = agentRole ? agentRole : role
    // agentRole
    const agentAccess = localStorage.getItem("agent_role") === "AGENT"
    const officeNames = JSON.parse(localStorage.getItem("officeName"));
    const officeId = JSON.parse(localStorage.getItem("officeId"));

    // Add "All" Option for Lived Trans
    if (agentRole === "ADMIN") {
        officeNames.unshift("All")
        officeId.unshift(null)
    }


    const [transactions, setTransactions] = useState([])
    const [filteredTransactions, setFilteredTransactions] = useState([])
    const [selectOfficeId, setSelectOfficeId] = useState(null)
    const [isSearch, setIsSearch] = useState(false)
    const [selectedTags, setSelectedTags] = useState(['Last 24 Hours']);
    const [selectedDates, setSelectedDates] = useState([]);

    const selectedDatesRef = useRef(selectedDates);
    const selectedTagsRef = useRef(selectedTags);

    const navigate = useNavigate();
    const {getColumnSearchProps} = useTableSearch();

    const navigateToDealDetail = (dealId, deal) => {
        const type = checkDealType(deal.client_type);
        const url = dealNavigate(type);
        navigate(url, {
            state: {
                dealId,
                deal
            },
        });
    };

    const fetchTransactions = useCallback(async () => {
        try {
            if (agentRole !== "ADMIN") {
                const res = await getLiveTransactions(agentId, agentRole)
                setTransactions(res)
            } else {
                // first render with "All Transactions" Tag
                const {startDate, endDate} = calculateTimePeriod(selectedTags);
                const res = await getLiveTransactionFilter(selectOfficeId, startDate, endDate)
                setTransactions(res)
            }
        } catch (e) {
            notification.error({
                message: 'Error',
                description: e.message || 'Something went wrong',
            });
        }
    }, [agentId, agentRole, selectOfficeId, selectedTags])

    const onChange = (val) => {

        // Set OfficeName and OfficeIndex for "All" Option
        if (val === "All") {
            setSelectOfficeId(null)
            return
        }

        const officeIndex = officeNames.indexOf(val)
        if (officeIndex !== -1) {
            setSelectOfficeId(officeId[officeIndex])
        } else {
            setSelectOfficeId(null)
        }
    }

    // SelectTag change function
    const handleChange = async (tag, checked) => {
        if (checked) {
            setSelectedTags([tag]);
            setSelectedDates([]);

            // isSearch means Admin search
            setIsSearch(true)
            await showSelectedTagTransactions(tag);
        }
    };

    const handleDateSelection = (date, dateString) => {
        setSelectedDates(dateString);
    };

    const showSelectedDateTransactions = useCallback(async (dates) => {
        if (dates.length < 2) {
            message.error("Please select both a start date and an end date.");
            return;
        }
        // Clear the tags since we are now using dates
        setSelectedTags([]);

        const [startDate, endDate] = dates;
        const res = await getLiveTransactionFilter(selectOfficeId, startDate, endDate);
        setIsSearch(true);
        setFilteredTransactions(res);
    }, [selectOfficeId]);

    const showSelectedTagTransactions = useCallback(async (tag) => {
        // Clear the dates since we are now using a tag
        setSelectedDates([]);
        const {startDate, endDate} = calculateTimePeriod([tag]);
        const res = await getLiveTransactionFilter(selectOfficeId, startDate, endDate);
        setFilteredTransactions(res);
        setIsSearch(true);
    }, [selectOfficeId]);


    useEffect(() => {
        if (!agentId) return;
        setSelectedDates([])
        setSelectedTags(['Last 24 Hours']);
    }, [agentId]);

    useEffect(() => {
        fetchTransactions()
    }, [fetchTransactions])


    useEffect(() => {
        selectedDatesRef.current = selectedDates;
        selectedTagsRef.current = selectedTags;
    }, [selectedDates, selectedTags]);

    useEffect(() => {
        if (agentRole === "ADMIN") {
            if (selectedDatesRef.current.length === 0) {
                showSelectedTagTransactions(selectedTagsRef.current[0]);
            } else {
                showSelectedDateTransactions(selectedDatesRef.current);
            }
        }
    }, [selectOfficeId, agentRole, showSelectedTagTransactions, showSelectedDateTransactions]);


    const columns = [
        ...(agentRole === "ADMIN"
                ? [{
                    title: "Office",
                    dataIndex: "office_name",
                    key: "office_name",
                    width: "10%",
                    sorter: (a, b) => a.office_name.localeCompare(b.office_name),
                    sortDirections: ['descend', 'ascend'],
                    ...getColumnSearchProps('office_name'),
                }] : []
        ),

        ...(agentAccess
                ? [] : [{
                    title: "Agent Name",
                    dataIndex: "agent_name",
                    key: "agent_name",
                    width: "15%",
                    sorter: (a, b) => a.agent_name.localeCompare(b.agent_name),
                    sortDirections: ['descend', 'ascend'],
                    ...getColumnSearchProps('agent_name'),
                }]
        ),
        {
            title: 'Client Type',
            dataIndex: 'client_type',
            width: '10%',
            filters: clientTypeFilters,
            render: (_, {client_type}) => {
                const typeColorMapping = {
                    RENTER: 'blue',
                    BUYER: 'blue',
                    INVESTOR: 'blue',
                    LANDLORD: 'blue',
                    SELLER: 'blue'
                };

                const tagColor = typeColorMapping[client_type] || 'geekblue';
                return (
                    <Tag color={tagColor}>
                        {client_type}
                    </Tag>
                );
            },
            onFilter: (value, record) => record.client_type.startsWith(value),
        },
        {
            title: "Customer Name",
            dataIndex: "client_name",
            key: "client_name",
            width: "15%",
            sorter: (a, b) => a.client_name.localeCompare(b.client_name),
        },
        {
            title: "Starting Date",
            dataIndex: "starting_date",
            key: "starting_date",
            width: "10%",
            sorter: (a, b) => new Date(a.starting_date) - new Date(b.starting_date),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: "Property Address",
            dataIndex: "property_address",
            key: "property_address",
            width: "25%",
            sorter: (a, b) => a.property_address.localeCompare(b.property_address),
            ...getColumnSearchProps('property_address'),
        },
        {
            title: "Deal Status",
            dataIndex: "deal_status",
            key: "deal_status",
            width: "10%",
            filters: statusFilter,
            onFilter: (value, record) => record.deal_status.startsWith(value),
            render: (_, {deal_status}) => {
                const tagColor = typeColorMapping[deal_status] || 'geekblue';
                return (
                    <Tag color={tagColor}>
                        {deal_status}
                    </Tag>
                );
            }
        },
        {
            title: "Days Stagnant",
            key: "days_stagnant",
            width: "10%",
            defaultSortOrder: "descend",
            sorter: (a, b) => getDaysStagnant(a.last_proceed_date) - getDaysStagnant(b.last_proceed_date),
            render: (_, record) => getDaysStagnant(record.last_proceed_date),
        },
        {
            title: 'Action',
            key: 'action',
            width: '10%',
            render: (_, record) => (
                <a
                    href={"/"}
                    onClick={(e) => {
                        e.preventDefault()
                        navigateToDealDetail(record.transaction_id, record)
                    }}>Detail</a>
            ),
        },
    ];

    return (
        <>
            {agentRole !== "ADMIN" && transactions &&
                <Title level={3}> Total Live Transactions: {transactions.length}</Title>}
            {agentRole === "ADMIN" &&
                <OfficeSelect officeNames={officeNames} onChange={onChange} defaultSelection={true}/>}
            {agentRole === "ADMIN" && <div className="date-selection-container">
                {/* Tags Section */}
                <div className="tags-container">
                    {tagsData.map((tag) => (
                        <CheckableTag
                            key={tag}
                            checked={selectedTags.includes(tag)}
                            onChange={(checked) => handleChange(tag, checked)}
                        >
                            {tag}
                        </CheckableTag>
                    ))}
                </div>

                {/* Date Picker & Button */}
                <div className="date-picker-container">
                    <RangePicker
                        format={dateFormat}
                        value={
                            selectedDates.length === 2
                                ? [
                                    moment(selectedDates[0], dateFormat),
                                    moment(selectedDates[1], dateFormat),
                                ]
                                : null
                        }
                        onChange={handleDateSelection}
                    />
                    <Button onClick={() => setSelectedDates([])}>Cancel</Button>
                    <Button type="primary" disabled={selectedDates.length < 2}
                            onClick={() => showSelectedDateTransactions(selectedDates)}>
                        Show Transactions
                    </Button>
                </div>
            </div>

            }
            <Table
                columns={columns}
                dataSource={isSearch ? filteredTransactions ?? [] : transactions ?? []}
            />

        </>
    );
}


export default LiveTransactionTable;