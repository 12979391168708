import React, { useEffect, useState, useRef, useCallback} from "react";
import "./DealDetail.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Divider, Col, Row, Form, Input, notification, Tooltip, Collapse, Modal, DatePicker} from "antd";
import moment from "moment";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import {
    editSubjectPropertyInfoById,
    allSteps,
    incrementSubjectOfProperty,
    decrementSubjectOfProperty,
    getSOPTransaction,
    getDaysStagnant, readableNumberFormat,
} from "../Utils/utils";
import DealStep from "./DealStep";

const { Panel } = Collapse;

function SubjectPropertyDeal() {
    const location = useLocation();
    const navigate = useNavigate();
    const formRef = useRef(null)

    // Pull the transaction ID and deal from state (if provided)
    const { dealId, deal } = location.state || {};
    const [dealData, setDealData] = useState(deal || {});
    const [editMode, setEditMode] = useState(false);
    const [closingInfo, setClosingInfoModal] = useState(false)
    const [loading, setLoading] = useState(false); // loading state for closing info modal
    const [form] = Form.useForm();

    // model type = "expected" if active -> contingent / pending
    // model type = "final" if pending -> closed
    // model type = null otherwise
    const [modalType, setModalType] = useState(null);

    // Determine steps based on clientType
    const openNotification = (description, title) => {
        notification.open({
            duration: 25,
            message: title,
            description: description,
            onClick: () => {
                console.log("Notification Clicked!");
            },
        });
    };

    // Fetch the full subject property deal info from the backend
    const fetchDeal = useCallback(async () => {
        if (!dealId) return;
        try {
            const res = await getSOPTransaction(dealId);
            setDealData(res);
        } catch (e) {
            openNotification("Can't fetch the transaction", "Error in SOP ");
            console.error(e);
        }
    }, [dealId]);

    // On component mount, fetch the deal if we have a dealId

    useEffect(() => {
        fetchDeal();
    }, [fetchDeal]);


    // If editMode is toggled, set form fields based on current dealData
    useEffect(() => {
        if (editMode) {
            form.setFieldsValue({
                notes: dealData.notes,
                expected_closing_date: dealData.expectedClosingDate
                    ? moment(dealData.expectedClosingDate, "YYYY-MM-DD")
                    : null,
                closing_price: dealData.closingPrice
            });
        }
    }, [editMode, dealData, form]);

    // Determine steps based on clientType, or an empty array if undefined
    const steps = allSteps[dealData?.clientType] || [];

    function getNextStatus() {
        if (!steps.length) return null;
        const nextIndex = (dealData.progressStep || 0);
        if (nextIndex < steps.length) {
            return steps[nextIndex].status;
        }
        return null;
    }

    // Toggle between read-only view and edit view
    const toggleEditMode = () => {
        setEditMode(!editMode);
    };

    const updateDeal = async (values) => {
        if (!dealData.id) {
            openNotification("Deal data is missing an ID", "Error");
            return;
        }

        const formattedDate = values.expected_closing_date
            ? values.expected_closing_date.format("YYYY-MM-DD")
            : null;

        const body = {
            notes: values.notes,
            expected_closing_date: formattedDate,
            closing_price: values.closing_price
        };

        try {
            await editSubjectPropertyInfoById(dealData.id, body);

            // Update local state with new values
            setDealData(prev => ({
                ...prev,
                notes: values.notes,
                expectedClosingDate: formattedDate,
                closingPrice: values.closing_price
            }));
            setEditMode(false);
            openNotification("Update deal successfully", "Success")
        } catch (error) {
            console.error(error);
            openNotification("Can't Edit the Deal", "Error");
        }
    };

    const openModalWithPrefill = (type) => {
        setModalType(type);

        // Pre-fill with the existing values if any
        formRef.current?.setFieldsValue({
            expected_closing_date: dealData.expectedClosingDate
                ? moment(dealData.expectedClosingDate, "YYYY-MM-DD")
                : null,
            closing_price: dealData.closingPrice ?? ""
        });

        setClosingInfoModal(true);
    };

    // Step control
    const forwardStep = async () => {
        const nextStatus = getNextStatus(); // e.g. "contingent", "pending", or "closed"

        // If the next status requires us to ask for closing info:
        if (dealData.status === "ACTIVE" && (nextStatus === "contingent" || nextStatus === "pending")) {
            openModalWithPrefill("expected");
            return; // Don’t increment step yet
        }

        // If the status changes from pending to closed => ask for final closing info
        if (dealData.status === "PENDING" && nextStatus === "closed") {
            openModalWithPrefill("final");
            return;
        }

        // Otherwise, if no modal is needed, just increment
        try {
            const updatedDeal = await incrementSubjectOfProperty(dealData.id);
            setDealData((prev) => ({
                ...prev,
                progressStep: updatedDeal.progress_step,
                status: updatedDeal.deal_status,
                lastProceedDate: updatedDeal.last_proceed_date,
                listingDate: updatedDeal.client_representation_date
            }));
        } catch (error) {
            console.error(error);
            openNotification("Unable to move forward", "Error");
        }
    };

    const backwardStep = async () => {
        try {
            const updatedDeal = await decrementSubjectOfProperty(dealData.id);
            setDealData((prev) => ({
                ...prev,
                progressStep: updatedDeal.progress_step,
                status: updatedDeal.deal_status,
                listingDate: updatedDeal.client_representation_date
            }));
        } catch (error) {
            console.error(error);
            openNotification("Unable to move backward", "Error");
        }
    };

    const handleOk = async () =>{
        try {
            await formRef.current.validateFields(); // Validate fields before submission
            formRef.current.submit();
        } catch (error) {
            console.error("Validation failed:", error);
        }
    }

    const submitForm = async (values) => {
        setLoading(true);
        try {
            const dateFormatted = values.expected_closing_date
                ? values.expected_closing_date.format("YYYY-MM-DD")
                : null;

            // Build the request body
            const body = {
                expected_closing_date: dateFormatted,
                closing_price: parseFloat(values.closing_price),
            };

            await editSubjectPropertyInfoById(dealData.id, body)

            // After updating, increment the step
            const updatedDeal = await incrementSubjectOfProperty(dealData.id);
            // Update local state with new step + new data
            setDealData((prev) => ({
                ...prev,
                progressStep: updatedDeal.progress_step,
                status: updatedDeal.deal_status,
                lastProceedDate: updatedDeal.last_proceed_date,
                expectedClosingDate: dateFormatted,
                closingPrice: parseFloat(values.closing_price)
            }));

            // Reset modal
            setClosingInfoModal(false);
            setModalType(null);
            formRef.current.resetFields();

        } catch (error) {
            console.error("Submission error:", error);
            openNotification("Unable to update closing info", "Error");
        } finally {
            setLoading(false);
        }
    };

    // Avoid rendering if we still have no dealData
    if (!dealData || !dealData.id) {
        return (
            <div style={{ padding: "20px" }}>
                <Button type="default" onClick={() => navigate(-1)}>Back</Button>
                <Divider />
                <h2>Deal is not available</h2>
            </div>
        );
    }

    // READ-ONLY VIEW
    const readOnlyView = (
        <>
            <Divider className="divider" orientation="left">Property Info</Divider>
            <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
                <Col span={12}>
                    <h1>
                        Address: &nbsp;<span className="text">{dealData.address}</span>
                    </h1>
                </Col>
                <Col span={12}>
                    <h1>
                        City: &nbsp;<span className="text">{dealData.city}</span>
                    </h1>
                </Col>
            </Row>

            <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
                <Col span={12}>
                    <h1>
                        State: &nbsp;<span className="text">{dealData.state}</span>
                    </h1>
                </Col>
                <Col span={12}>
                    <h1>
                        Zip: &nbsp;<span className="text">{dealData.zip}</span>
                    </h1>
                </Col>
            </Row>

            {/* Transaction Info Section */}
            <Divider className="divider" orientation="left">Transaction Info</Divider>
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <h1>
                        Expected Closing Date:&nbsp;
                        <span className="text">{dealData.expectedClosingDate || "N/A"}</span>
                    </h1>
                </Col>
                <Col span={12}>
                    <h1>
                        Closing Price:&nbsp;
                        <span className="text">{dealData.closingPrice ? readableNumberFormat(dealData.closingPrice) : "N/A"}</span>
                    </h1>
                </Col>
            </Row>

            <Divider className="divider" orientation="left">Notes</Divider>
            <Row>
                <Col span={24}>
                    <h1>
                        Notes: &nbsp;
                        <span className="text">
                            {dealData.notes ? dealData.notes : "N/A"}
                        </span>
                    </h1>
                </Col>
            </Row>
        </>
    );

    // EDIT VIEW
    const editView = (
        <>
            <Divider className="divider" orientation="left">Property Info</Divider>
            <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
                <Col span={12}>
                    <h1>
                        Address: &nbsp;<span className="text">{dealData.address}</span>
                    </h1>
                </Col>
                <Col span={12}>
                    <h1>
                        City: &nbsp;<span className="text">{dealData.city}</span>
                    </h1>
                </Col>
            </Row>

            <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
                <Col span={12}>
                    <h1>
                        State: &nbsp;<span className="text">{dealData.state}</span>
                    </h1>
                </Col>
                <Col span={12}>
                    <h1>
                        Zip: &nbsp;<span className="text">{dealData.zip}</span>
                    </h1>
                </Col>
            </Row>

            <Form form={form} layout="vertical" onFinish={updateDeal}>
                {/* Transaction Info Section */}
                <Divider className="divider" orientation="left">Transaction Info</Divider>
                <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
                    <Col span={12}>
                        <div className="inline-input-container">
                            <h1 className="label">Expected Closing Date: &nbsp;</h1>
                            <Form.Item
                                name="expected_closing_date"
                                style={{ marginBottom: 0, flex: "auto" }}
                                rules={[
                                    {
                                        validator: (_, value) => {
                                            if (dealData.progressStep > 9 && !value) {
                                                return Promise.reject(new Error('Expected Closing Price should be entered'));
                                            }
                                            return Promise.resolve();
                                        }
                                    }
                                ]}
                            >
                                <DatePicker format="YYYY-MM-DD" disabled={dealData.status === "CLOSED"}/>
                            </Form.Item>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="inline-input-container">
                            <h1 className="label">Closing Price: &nbsp;</h1>
                            <Form.Item name="closing_price" label="Closing Price" style={{marginBottom: 0, flex: "auto"}}
                                       rules={[
                                           {
                                               validator: (_, value) => {
                                                   if (dealData.progressStep > 9 && !value) {
                                                       return Promise.reject(new Error('Closing Price is required'));
                                                   }
                                                   return Promise.resolve();
                                               }
                                           }
                                       ]}
                            >
                                <Input disabled={dealData.status === "CLOSED"}/>
                            </Form.Item>
                        </div>
                    </Col>
                </Row>

                <Divider className="divider" orientation="left">Notes</Divider>
                <Row>
                    <Col span={24}>
                        <div className="inline-input-container">
                            <h1 className="label">Notes: &nbsp;</h1>
                            <Form.Item name="notes" label="notes" style={{ marginBottom: 0, flex: "auto" }}>
                                <Input.TextArea rows={10} />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Button type="primary" htmlType="submit" className="submitButton">
                        Save
                    </Button>
                </Row>
            </Form>
        </>
    );

    let stagnantDays = getDaysStagnant(dealData.lastProceedDate);

    return (
        <>
            <Button type="default" onClick={() => navigate(-1)}>Back</Button>
            <Divider />
            <div className="header-container">
                <h1>
                    Client Type: &nbsp;&nbsp; <span className="text">{dealData.clientType}</span>
                </h1>
                <Button type="primary" className="editDeal" onClick={toggleEditMode}>
                    {editMode ? "Cancel Edit" : "Edit Deal"}
                </Button>
            </div>

            <div className="page-container">
                {/* Info Section */}
                <div className="left-section">
                    <Divider className="divider" orientation="left">Basic Info</Divider>
                    <Row gutter={[60, 16]} align="middle">
                        <Col span={12}>
                            <h1>
                                Status: &nbsp; <span className="text">{dealData.status || "N/A"}</span>
                            </h1>
                        </Col>
                        <Col span={12}>
                            <h1>
                                Progress Step: &nbsp; <span className="text">{dealData.progressStep ?? 1}</span>
                            </h1>
                        </Col>
                        <Col span={12}>
                            <h1>
                                Starting Date: &nbsp; <span className="text">{dealData.startingDate || "N/A"}</span>
                            </h1>
                        </Col>
                        <Col span={12}>
                            <h1>
                                Listing Date: &nbsp; <span className="text">{dealData.listingDate || "N/A"}</span>
                            </h1>
                        </Col>
                        <Col span={12}>
                            <h1>
                                Days Stagnant: &nbsp;
                                <span className="text">{stagnantDays}</span>
                            </h1>
                        </Col>
                    </Row>

                    {editMode ? editView : readOnlyView}
                </div>

                {/* Progress Section */}
                <div className="right-section">
                    <Collapse defaultActiveKey={["1"]}>
                        <Panel header="Transaction Progress" key="1">
                            <div className="stepButtons">
                                <Tooltip placement="topRight" title="Backward 1 Step">
                                    <Button onClick={backwardStep} data-testid="step-backward">
                                        <LeftOutlined />
                                    </Button>
                                </Tooltip>

                                <Tooltip placement="topLeft" title="Forward 1 Step">
                                    <Button style={{ marginLeft: "10px" }} onClick={forwardStep} data-testid="step-forward">
                                        <RightOutlined />
                                    </Button>
                                </Tooltip>
                            </div>
                            <DealStep
                                steps={steps}
                                currentStep={dealData.progressStep || 0}
                                dealData={dealData}
                            />
                        </Panel>
                    </Collapse>
                </div>
            </div>

            <Modal
                title={modalType === "expected" ? "Update the closing info" : "Confirm the closing info"}
                open={closingInfo}
                onOk={handleOk}
                onCancel={() => setClosingInfoModal(false)}
                confirmLoading={loading}
            >
                <Form ref={formRef} onFinish={submitForm} layout="vertical">
                    <Form.Item
                        name="expected_closing_date"
                        label={modalType === "expected" ? "Expected Closing Date" : "Closing Date"}
                        rules={[
                            { required: true },
                            {
                                validator: (_, value) => {
                                    if (modalType === "final" && value && value > moment().endOf("day")) {
                                        return Promise.reject(new Error("Closing date cannot be in the future."));
                                    }
                                    return Promise.resolve();
                                },
                            },
                        ]}
                    >
                        <DatePicker
                            format="YYYY-MM-DD"
                            disabledDate={modalType === "final" ? (current) => current && current > moment().endOf("day") : undefined}
                        />
                    </Form.Item>
                    <Form.Item
                        name="closing_price"
                        label={modalType === "expected" ? "Expected Closing Price" : "Closing Price"}
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>

        </>
    );
}

export default SubjectPropertyDeal;