import React, {useCallback, useEffect, useState} from 'react';
import useTableSearch from "../../Utils/useTableSearch";
import {getOfficeByAdmin, navigateTo} from "../../Utils/utils";
import {Divider, Space, Table, Typography, notification} from "antd";
import {useNavigate} from "react-router-dom";

const {Title} = Typography;

function OfficeTable() {
    const navigate = useNavigate();
    const {getColumnSearchProps} = useTableSearch()
    const [office, setOffice] = useState([])
    const [loading, setLoading] = useState(false);


    const fetchOffices = useCallback(async()=>{
        setLoading(true);

        try{
            const res = await getOfficeByAdmin()
            console.log(res)
            setOffice(res)

            const officeName = res.map(item => item.name);
            localStorage.setItem("officeName", JSON.stringify(officeName));
            const officeId = res.map(item => item.id);
            localStorage.setItem("officeId", JSON.stringify(officeId));
        }
        catch(e){
            notification.error({
                message: 'Error',
                description: e.message || 'Something went wrong',
            });
        }
        finally {
            setLoading(false);
        }
    },[])

    useEffect(() => {
        fetchOffices();
    }, [fetchOffices]);

    const columns = [
        {
            title: "Office Name",
            dataIndex: "name",
            key: 'name',
            width: "15%",
            sorter: (a, b) => a.name.localeCompare(b.name),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('name'),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            sorter: (a, b) => a.email.localeCompare(b.email),
            sortDirections: ['descend', 'ascend'],
            key: 'email',
            width: "15%",
            ...getColumnSearchProps('email'),
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            width: "10%",
            ...getColumnSearchProps('phone'),
        },

        {
            title: "Total customers",
            dataIndex: "customer_count",
            width: "10%",
            sorter: (a, b) => a.customer_count - b.customer_count,
        },
        {
            title: "Total live transactions",
            dataIndex: "transaction_count",
            width: "10%",
            sorter: (a, b) => a.transaction_count - b.transaction_count,
        },
        {
            title: "Total pre transactions",
            dataIndex: "pre_transaction_count",
            width: "10%",
            sorter: (a, b) => a.pre_transaction_count - b.pre_transaction_count,
        },
        {
            title: "YTD Sales Volume",
            dataIndex: "ytd_sales_volume",
            width: "10%",
            sorter: (a, b) => a.ytd_sales_volume - b.ytd_sales_volume,
        },
        {
            title: "Total Agents",
            dataIndex: "agent_count",
            width: "10%",
            sorter: (a, b) => a.agent_count - b.agent_count,
        },
        {
            title: 'Action',
            key: 'action',
            width: "10%",
            render: (_, record) => (
                <Space size="middle">
                    <a
                        href={`/offices/${record.id}`}
                        onClick={(e) => {
                            e.preventDefault(); // Prevents scrolling to top
                            const path = `/offices/${record.id}`;
                            navigateTo(navigate, path, record);
                        }}
                    >
                        Office Detail</a>
                </Space>
            ),
        }
    ]

    return (<>
        <Title>Total Offices: {office?.length ?? 0}</Title>

        <Divider orientation="left" style={{marginTop: "20px"}}>Office Table</Divider>
        <Table
            columns={columns}
            dataSource={office}
            loading={loading}
        />

    </>)
}

export default OfficeTable;