import React, {useEffect} from 'react';
import { Form, Input, Button, Row, Col, Select } from "antd";


export const OfficeSelect = ({ officeNames, onChange, style = { width: "50%", marginBottom:"2%"}, size = "large", placeholder = "Select an office", defaultSelection=false}) => {
    const options = [
        ...officeNames.map((name) => ({
            value: name,
            label: name,
        })),
    ];

    return (
        <Select
            style={style}
            size={size}
            showSearch
            placeholder={placeholder}
            optionFilterProp="children"
            onChange={onChange}
            filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            defaultValue={defaultSelection ? "All" : undefined} // Set default value if defaultSelection is true
            options={options}
        />
    );
};


export const SearchByDetail = ({ onSearch, form, buttonText = "Search" }) => {
     const fields = [
        { name: "name", placeholder: "Enter Name" },
        { name: "email", placeholder: "Enter Email" },
        { name: "phone", placeholder: "Enter Phone" },
    ];


    const handleSearch = () => {
        const values = form.getFieldsValue();
        onSearch(values);
    };

    useEffect(() => {
        form.resetFields();
    }, [form]);

    return (
        <Form form={form} layout="inline" onFinish={handleSearch}>
            <Row gutter={16} style={{ width: "100%", marginBottom: "16px" }}>
                {fields.map(({ name, placeholder }, index) => (
                    <Col span={6} key={name || index}>
                        <Form.Item name={name}>
                            <Input
                                placeholder={placeholder}
                                allowClear
                                onPressEnter={handleSearch}
                            />
                        </Form.Item>
                    </Col>
                ))}
                <Col span={6}>
                    <Button type="primary" htmlType="submit">
                        {buttonText}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};
