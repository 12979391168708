import {Button, Form, Input, message, Radio} from "antd";
import React from "react";
import {useState} from "react";
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import {useAuth} from "../Utils/AuthContext";

const LoginForm = ({onSuccess}) => {
    const containerStyle = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
        paddingTop: "5%",
        transform: "scale(1.25)",
        transformOrigin: "center"
    }
    const [selectedRole, setRole] = useState("AGENT")
    const [loading, setLoading] = useState(false);

    const {handleLogIn} = useAuth();

    const onFinish = async (data) => {
        setLoading(true);
        try {
            const userData = await handleLogIn(data, selectedRole);
            message.success("Login Successful");
            if(userData.agent_role){
                onSuccess(userData.user_id);
            }
            else if(userData.role === "OFFICE"){
                onSuccess(userData.office_id)
            }
        } catch (err) {
            console.log(err)
            message.error(err.response.data.message);
        } finally {
            setLoading(false);
        }
    };

    const onFinishFailed = () => {
        console.log("error")
    };


    const onChange = (e) => {
        setRole(e.target.value);
    };

    return (
        <>
            <div
                style={containerStyle}
            >
                {/* Radio Group */}
                <Radio.Group onChange={onChange} value={selectedRole}>
                    <Radio value={"AGENT"}>Agent / Manager</Radio>
                    <Radio value={"OFFICE"}>Office</Radio>
                    <Radio value={"ADMIN"}>Admin</Radio>
                </Radio.Group>

                {/* Form */}
                <Form
                    name="normal_login"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    style={{
                        width: "15%",
                        marginTop: "2%",
                    }}
                >
                    <Form.Item
                        name="email"
                        rules={[{required: true, message: "Please input your Email!"}]}
                    >
                        <Input prefix={<UserOutlined/>} placeholder="Email"/>
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[{required: true, message: "Please input your Password!"}]}
                    >
                        <Input.Password prefix={<LockOutlined/>} placeholder="Password"/>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading} block>
                            Login
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </>
    );
};

export default LoginForm;