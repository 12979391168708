import React, { useEffect, useState, useCallback} from "react";
import { Card, Typography, Descriptions, Spin, Modal, Button, Input, message } from "antd";
import { useAuth } from "../Utils/AuthContext";
import {
    getAdminById,
    getAgentById,
    getOfficeById,
    profilePasswordChange,
    profilePhoneChange
} from "../Utils/utils";

const { Title } = Typography;

const Profile = () => {
    const { userId } = useAuth();
    const role = localStorage.getItem("agent_role");
    const [loading, setLoading] = useState(true);
    const [profileData, setProfileData] = useState(null);

    // States for Change Password Modal
    const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");

    // States for Update Phone Modal
    const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false);
    const [newPhone, setNewPhone] = useState("");

    const fetchProfile = useCallback(async () => {
        try {
            let res = [];
            if (role === "AGENT" || role === "MANAGER") {
                res = await getAgentById(userId);
            } else if (role === "OFFICE") {
                res = await getOfficeById(userId);
            } else if (role === "ADMIN") {
                res = await getAdminById(userId);
            }
            setProfileData(res);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }, [userId, role]);

    useEffect(() => {
        if (userId) {
            fetchProfile();
        }
    }, [userId, fetchProfile]);

    // Handlers for Change Password
    const handlePasswordModalOk = async () => {

        const requestValues = {
            new_password: newPassword,
            current_password: currentPassword,
            role,
            user_id : userId
        }

        try {
            await profilePasswordChange(requestValues);
            message.success("Password updated successfully!");
            setIsPasswordModalOpen(false);
            setCurrentPassword("");
            setNewPassword("");
        } catch (error) {
            console.error(error);
            message.error(`Failed to update password: ${error.response?.data?.message}`);
        }
    };

    const handlePasswordModalCancel = () => {
        setIsPasswordModalOpen(false);
        setCurrentPassword("");
        setNewPassword("");
    };

    // Handlers for Update Phone
    const handlePhoneModalOk = async () => {
        const digitsOnly = newPhone.replace(/\D/g, "");

        const requestValues = {
            role,
            user_id: userId,
            phone: digitsOnly
        }
        try {

            // Must be exactly 10 digits
            if (digitsOnly.length !== 10) {
                message.error("Phone number must be exactly 10 digits.");
                return;
            }
            await profilePhoneChange(requestValues);

            // Update local profile data to reflect new phone
            setProfileData((prevData) => ({
                ...prevData,
                phone: digitsOnly
            }));

            message.success("Phone number updated successfully!");
            setIsPhoneModalOpen(false);
            setNewPhone("");
        } catch (error) {
            console.error(error);
            message.error(`Failed to update phone number: ${error.response.data}` );
        }
    };

    const handlePhoneModalCancel = () => {
        setIsPhoneModalOpen(false);
        setNewPhone("");
    };

    if (loading) {
        return <Spin style={{ display: "block", margin: "auto" }} />;
    }

    if (!profileData) {
        return <div>No profile data found.</div>;
    }

    const {
        name,
        office_name,
        first_name,
        last_name,
        email,
        phone,
        agent_role,
        address,
        city,
        state,
        zip
    } = profileData;

    const roleFieldConfig = {
        AGENT: [
            { label: "Office", value: office_name },
            { label: "First Name", value: first_name },
            { label: "Last Name", value: last_name },
            { label: "Role", value: agent_role },
            { label: "Email", value: email },
            {
                label: "Password",
                value: (
                    <>
                        ******** &nbsp;
                        <Button type="link" onClick={() => setIsPasswordModalOpen(true)}>
                            Update Password
                        </Button>
                    </>
                )
            },
            {
                label: "Phone",
                value: (
                    <>
                        {phone || "N/A"} &nbsp;
                        <Button type="link" onClick={() => setIsPhoneModalOpen(true)}>
                            Update Phone
                        </Button>
                    </>
                )
            }
        ],
        MANAGER: [
            { label: "Office", value: office_name },
            { label: "First Name", value: first_name },
            { label: "Last Name", value: last_name },
            { label: "Role", value: agent_role },
            { label: "Email", value: email },
            {
                label: "Password",
                value: (
                    <>
                        ******** &nbsp;
                        <Button type="link" onClick={() => setIsPasswordModalOpen(true)}>
                            Update Password
                        </Button>
                    </>
                )
            },
            {
                label: "Phone",
                value: (
                    <>
                        {phone || "N/A"} &nbsp;
                        <Button type="link" onClick={() => setIsPhoneModalOpen(true)}>
                            Update Phone
                        </Button>
                    </>
                )
            }
        ],
        OFFICE: [
            { label: "Office Name", value: name },
            { label: "Role", value: role },
            { label: "Email", value: email },
            { label: "Address", value: address + ", " + city + ", " + state + " " + zip },
            {
                label: "Password",
                value: (
                    <>
                        ******** &nbsp;
                        <Button type="link" onClick={() => setIsPasswordModalOpen(true)}>
                            Update Password
                        </Button>
                    </>
                )
            },
            {
                label: "Phone",
                value: (
                    <>
                        {phone || "N/A"} &nbsp;
                        <Button type="link" onClick={() => setIsPhoneModalOpen(true)}>
                            Update Phone
                        </Button>
                    </>
                )
            }
        ],
        ADMIN: [
            { label: "First Name", value: first_name },
            { label: "Last Name", value: last_name },
            { label: "Role", value: role },
            { label: "Email", value: email },
            {
                label: "Password",
                value: (
                    <>
                        ******** &nbsp;
                        <Button type="link" onClick={() => setIsPasswordModalOpen(true)}>
                            Update Password
                        </Button>
                    </>
                )
            },
            {
                label: "Phone",
                value: (
                    <>
                        {phone || "N/A"} &nbsp;
                        <Button type="link" onClick={() => setIsPhoneModalOpen(true)}>
                            Update Phone
                        </Button>
                    </>
                )
            }
        ]
    };
    roleFieldConfig.MANAGER = roleFieldConfig.AGENT;
    const fieldsToDisplay = roleFieldConfig[role] || [];


    return (
        <Card style={{ maxWidth: 600, margin: "0 auto" }}>
            <Title level={3}>User Profile</Title>
            <Descriptions bordered column={1}>
                {fieldsToDisplay.map(({ label, value }) => (
                    <Descriptions.Item key={label} label={label}>
                        {value || "N/A"}
                    </Descriptions.Item>
                ))}
            </Descriptions>

            {/* Password Modal */}
            <Modal
                title="Change Password"
                open={isPasswordModalOpen}
                onOk={handlePasswordModalOk}
                onCancel={handlePasswordModalCancel}
                okText="Update"
            >
                <p>Please enter your current password:</p>
                <Input.Password
                    placeholder="Current Password"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    style={{ marginBottom: "10px" }}
                />
                <p>Please enter your new password:</p>
                <Input.Password
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                />
            </Modal>

            {/* Phone Modal */}
            <Modal
                title="Update Phone Number"
                open={isPhoneModalOpen}
                onOk={handlePhoneModalOk}
                onCancel={handlePhoneModalCancel}
                okText="Update"
            >
                <p>Please enter your new phone number:</p>
                <Input
                    placeholder="New Phone Number"
                    value={newPhone}
                    onChange={(e) => setNewPhone(e.target.value)}
                />
            </Modal>
        </Card>
    );
};

export default Profile;