import React, { useState } from "react";
import { Button, Form, Input, message, Modal, Radio } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { createUser } from "../Utils/utils";

function SignupForm({ onSuccess }) {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedRole, setSelectedRole] = useState("AGENT");

    const handleOpenModal = () => {
        setIsModalVisible(true);
    };
    const handleCloseModal = () => {
        setIsModalVisible(false);
    };
    const handleRadioChange = (e) => {
        setSelectedRole(e.target.value);
    };

    const phoneNumberRegex = /^[0-9]{10}$/;

    // On successful form submit, call createUser
    const createAccount = async (formValues) => {
        try {
            await createUser(formValues, selectedRole);
            message.success("Successfully signed up");
            handleCloseModal();
            if (onSuccess) onSuccess();
        } catch (error) {
            message.error(error.message);
        }
    };

    // Agent and Manager form
    const agentManagerForm = () => {
        return (
            <Form onFinish={createAccount}>
                <Form.Item
                    name="email"
                    rules={[{ required: true, message: "Please input your email!" }]}
                >
                    <Input prefix={<UserOutlined />} placeholder="Email" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[{ required: true, message: "Please input your password!" }]}
                >
                    <Input prefix={<LockOutlined />} placeholder="Password" />
                </Form.Item>
                <Form.Item
                    name="first_name"
                    rules={[{ required: true, message: "Please input your first name!" }]}
                >
                    <Input placeholder="First Name" />
                </Form.Item>
                <Form.Item
                    name="last_name"
                    rules={[{ required: true, message: "Please input your last name!" }]}
                >
                    <Input placeholder="Last Name" />
                </Form.Item>
                <Form.Item
                    name="phone"
                    rules={[
                        { required: true, message: "Please input your phone!" },
                        { pattern: phoneNumberRegex, message: 'Phone number is not valid!' }
                    ]}
                >
                    <Input placeholder="Phone" />
                </Form.Item>
                <Form.Item
                    name="office"
                    rules={[{ required: true, message: "Please input your office!" }]}
                >
                    <Input placeholder="Office" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Register {selectedRole}
                    </Button>
                </Form.Item>
            </Form>
        );
    };

    // Office form
    const officeForm = () => {
        return (
            <Form onFinish={createAccount}>
                <Form.Item
                    name="email"
                    rules={[{ required: true, message: "Please input your email!" }]}
                >
                    <Input prefix={<UserOutlined />} placeholder="Email" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[{ required: true, message: "Please input your password!" }]}
                >
                    <Input prefix={<LockOutlined />} placeholder="Password" />
                </Form.Item>
                <Form.Item
                    name="name"
                    rules={[{ required: true, message: "Please input your Office Name!" }]}
                >
                    <Input placeholder="Office Name" />
                </Form.Item>
                <Form.Item
                    name="phone"
                    rules={[
                        { required: true, message: "Please input phone!" },
                        { pattern: phoneNumberRegex, message: 'Phone number is not valid!'}
                    ]}
                >
                    <Input placeholder="Phone" />
                </Form.Item>
                <Form.Item
                    name="address"
                    extra="Please include unit number if applicable."
                    rules={[{ required: true, message: "Please input address!" }]}
                >
                    <Input placeholder="Address" />
                </Form.Item>
                <Form.Item
                    name="city"
                    rules={[{ required: true, message: "Please input city!" }]}
                >
                    <Input placeholder="City" />
                </Form.Item>
                <Form.Item
                    name="state"
                    rules={[{ required: true, message: "Please input state!" }]}
                >
                    <Input placeholder="State" />
                </Form.Item>
                <Form.Item
                    name="zip"
                    rules={[{ required: true, message: "Please input ZIP!" }]}
                >
                    <Input placeholder="Zip" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Register OFFICE
                    </Button>
                </Form.Item>
            </Form>
        );
    };

    // Admin form:
    const adminForm = () => {
        return (
            <Form onFinish={createAccount}>
                <Form.Item
                    name="email"
                    rules={[{ required: true, message: "Please input your email!" }]}
                >
                    <Input prefix={<UserOutlined />} placeholder="Email" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[{ required: true, message: "Please input your password!" }]}
                >
                    <Input prefix={<LockOutlined />} placeholder="Password" />
                </Form.Item>
                <Form.Item
                    name="first_name"
                    rules={[{ required: true, message: "Please input your first name!" }]}
                >
                    <Input placeholder="First Name" />
                </Form.Item>
                <Form.Item
                    name="last_name"
                    rules={[{ required: true, message: "Please input your last name!" }]}
                >
                    <Input placeholder="Last Name" />
                </Form.Item>
                <Form.Item
                    name="phone"
                    rules={[
                        { required: true, message: "Please input your phone!" },
                        { pattern: phoneNumberRegex, message: 'Phone number is not valid!' }
                    ]}
                >
                    <Input placeholder="Phone" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Register ADMIN
                    </Button>
                </Form.Item>
            </Form>
        );
    };

    return (
        <div style={{ padding: "20px" }}>
            <Button type="primary" onClick={handleOpenModal}>
                Register Agent / Manager / Office / Admin
            </Button>

            <Modal
                title="Select and Fill a Form"
                open={isModalVisible}
                footer={null}
                onCancel={handleCloseModal}
            >
                {/* Role selection */}
                <Radio.Group
                    onChange={handleRadioChange}
                    value={selectedRole}
                    style={{ marginBottom: "20px" }}
                >
                    <Radio value="AGENT">AGENT</Radio>
                    <Radio value="MANAGER">MANAGER</Radio>
                    <Radio value="OFFICE">OFFICE</Radio>
                    <Radio value="ADMIN">ADMIN</Radio>
                </Radio.Group>

                {/* Conditional Form Rendering */}
                {selectedRole === "AGENT" || selectedRole === "MANAGER" ? agentManagerForm() : null}
                {selectedRole === "OFFICE" ? officeForm() : null}
                {selectedRole === "ADMIN" ? adminForm() : null}
            </Modal>
        </div>
    );
}

export default SignupForm;