import {Button, Form, Input, DatePicker, message, Typography, Select} from 'antd';
import React, {useState} from 'react';
import {useNavigate,useLocation} from "react-router-dom";
import axiosInstance from "../Utils/axiosInstance";

const {Title} = Typography;
const { TextArea } = Input;

const layout = {
    labelCol: {
        span: 7,
    },
    wrapperCol: {
        span: 13,
    },
};
/* eslint-disable no-template-curly-in-string */
const validateMessages = {
    required: '${label} is required!',
    types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};

const phoneNumberRegex = /^(?:\+?1[-.\s]?)?\(?([2-9][0-9]{2})\)?[-.\s]?([2-9][0-9]{2})[-.\s]?([0-9]{4})$/;

const sourceOption = {
    "Referral Source": "Agent Referral",
    "Enter the Name of Social Media Platform": "Social Media",
    "Enter the Site Name": "Internet Site",
    "Enter the Name of the Person": "Word of Mouth",
    "Enter the Name of Client": "Repeat Client",
    "Enter the name of Sphere of Influence": "Sphere of Influence",
    "": "Others"
}


const ClientRegistrationForm = () => {
    const [source, setSource] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    const prop = location.state;

    const onFinish = async (values) => {
        try {
            const client = values.client
            client['agent_id'] = prop.id
            client["notes"] = values['notes']
            client['date_registered'] = client['date_registered'].format('YYYY-MM-DD');

            const selectedLabel = sourceOption[source];
            const leadInput = values["lead_source"];

            if (leadInput && selectedLabel) {
                client['lead_source'] = `${selectedLabel}: ${leadInput}`;
            } else if (selectedLabel === "Others") {
                client['lead_source'] = `Others`
            } else {
                client['lead_source'] = undefined;
            }

            const url = `/clients/register`
            const response = await axiosInstance.post(url, client)
            const data = response.data;
            message.success("Form submitted successfully!");
            navigate(-1)

            console.log("Response from server:", data);
        } catch (error) {
            const status = error.response?.status;

            if(status === 401){
                window.location.href = "/";
                localStorage.clear()
                return
            }
            const errorMsg = error.response?.data || error.message;
            message.error(errorMsg);
        }

    };

    const handleChange = (value) => {
        setSource(value)
    };

    return (
        <Form {...layout} name="nest-messages" onFinish={onFinish} validateMessages={validateMessages}>

            <Title level={2} style={{textAlign: 'center'}}>Customer Registration Form</Title>
            <Title level={5} style={{textAlign: 'center', marginBottom: "25px"}} ><span>Agent Name:</span> {prop.fullName}</Title>

            <Form.Item
                name={['client', 'date_registered']}
                label="Registration Date"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <DatePicker/>
            </Form.Item>

            <Form.Item
                name={['client', 'first_name']}
                label="Customer First Name"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                name={['client', 'last_name']}
                label="Customer Last Name"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                name={['client', 'additional_name']}
                label="Additional Customer Name (If Multiple Parties)"
                rules={[
                    {
                        required: false,
                    },
                ]}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                name={['client', 'address']}
                extra="Please include unit number if applicable."
                label="Current Address"
                rules={[
                    {
                        required: false,
                    },
                ]}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                name={['client', 'city']}
                label="City"
                rules={[
                    {
                        required: false,
                    },
                ]}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                name={['client', 'state']}
                label="State"
                rules={[
                    {
                        required: false,
                    },
                ]}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                name={['client', 'zip']}
                label="Zip Code"
                rules={[
                    {
                        required: false,
                    },
                ]}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                name={['client', 'email']}
                label="Email"
                rules={[
                    {
                        type: 'email',
                        required: true,
                    },
                ]}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                name={['client', 'phone']}
                label="Phone Number"
                rules={[
                    {required: false},
                    {pattern: phoneNumberRegex, message: 'Phone number is not valid!'},
                ]}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                label="Lead Source"
                rules={[{required: false}]}
            >
                <Select
                    defaultValue="Others"
                    style={{ width: 200 }}
                    onChange={handleChange}
                    options={[
                        {
                            value: 'Referral Source',
                            label: 'Agent Referral',
                        },
                        {
                            value: 'Enter the Name of Social Media Platform',
                            label: 'Social Media',
                        },
                        {
                            value: 'Enter the Site Name',
                            label: 'Internet Site',
                        },
                        {
                            value: 'Enter the Name of the Person',
                            label: 'Word of Mouth',
                        },
                        {
                            value: 'Enter the Name of Client',
                            label: 'Repeat Client',
                        },
                        {
                            value: 'Enter the Sphere of Influence',
                            label: 'Sphere of Influence',
                        },
                        {
                            value: '',
                            label: 'Others',
                        },

                    ]}
                />
            </Form.Item>
            <Form.Item  wrapperCol={{
                ...layout.wrapperCol,
                offset: 7,
            }}
                        name="lead_source">
                <Input  placeholder={source}/>
            </Form.Item>

            <Form.Item name="notes" label="Notes">
                <TextArea rows={4} />
            </Form.Item>

            <Form.Item
                wrapperCol={{
                    ...layout.wrapperCol,
                    offset: 7,
                }}
            >
                <Button type="primary" htmlType="submit" >
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default ClientRegistrationForm;
