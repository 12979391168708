import React, {useEffect, useRef, useState, useCallback} from "react";
import DealStep from "./DealStep";
import "./DealDetail.css";
import { useLocation, useNavigate } from "react-router-dom";
import {Button, Divider, Col, Row, Form, Input, notification, Tooltip, Collapse, Modal, DatePicker} from "antd";
import moment from "moment";
import {
    editAreaOfInterestInfoById,
    allSteps,
    incrementAreaOfInterest,
    decrementAreaOfInterest,
    getAOITransaction,
    getDaysStagnant, readableNumberFormat,
} from "../Utils/utils";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

function AreaOfInterestDeal() {
    const location = useLocation();
    const navigate = useNavigate();
    const formRef = useRef(null)

    // Retrieve dealId and any initial deal data passed in via navigation
    const { dealId, deal } = location.state || {};

    const [editMode, setEditMode] = useState(false);
    const [dealData, setDealData] = useState(deal || {}); // start with passed-in deal or empty object
    const [form] = Form.useForm();

    const [closingInfo, setClosingInfoModal] = useState(false)
    const [loading, setLoading] = useState(false); // loading state for closing info modal

    // model type = "expected" if active -> contingent / pending
    // model type = "final" if pending -> closed
    // model type = null otherwise
    const [modalType, setModalType] = useState(null);

    const openNotification = (description, title) => {
        notification.open({
            duration: 25,
            message: title,
            description: description,
            onClick: () => {
                console.log("Notification Clicked!");
            },
        });
    };

    // Fetch the Area of Interest transaction from backend using the dealId
    const fetchDeal = useCallback(async () => {
        if (!dealId) return;
        try {
            const res = await getAOITransaction(dealId);
            setDealData(res);
        } catch (e) {
            console.error(e);
            openNotification("Can't fetch the transaction", "Error");
        }
    }, [dealId]);


    // On mount, fetch the deal if we have a dealId
    useEffect(() => {
        fetchDeal();
    }, [fetchDeal]);

    // When editMode toggles on, populate form fields
    useEffect(() => {
        if (editMode) {
            form.setFieldsValue({
                city: dealData.city,
                neighborhood: dealData.neighborhood,
                property_type: dealData.propertyType,
                number_of_bedrooms: dealData.numberOfBedrooms,
                number_of_bathrooms: dealData.numberOfBathrooms,
                min_price: dealData.minPrice,
                max_price: dealData.maxPrice,
                property_details: dealData.propertyDetails,
                specific_property: dealData.specificProperty,
                business_opportunity: dealData.businessOpportunity,
                notes: dealData.notes,
                expected_closing_date: dealData.expectedClosingDate
                    ? moment(dealData.expectedClosingDate, "YYYY-MM-DD")
                    : null,
                closing_price: dealData.closingPrice
            });
        }
    }, [editMode, dealData, form]);

    // If clientType isn't defined yet, steps might be empty
    const steps = allSteps[dealData?.clientType] || [];

    function getNextStatus() {
        if (!steps.length) return null;
        const nextIndex = (dealData.progressStep || 0);
        if (nextIndex < steps.length) {
            return steps[nextIndex].status;
        }
        return null;
    }

    const toggleEditMode = () => {
        setEditMode(!editMode);
    };

    const updateDeal = async (values) => {
        if (!dealData.id) {
            openNotification("Deal data is missing an ID", "Error");
            return;
        }

        const formattedDate = values.expected_closing_date
            ? values.expected_closing_date.format("YYYY-MM-DD")
            : null;

        const body = {
            city: values.city,
            neighborhood: values.neighborhood,
            property_type: values.property_type,
            number_of_bedrooms: values.number_of_bedrooms,
            number_of_bathrooms: values.number_of_bathrooms,
            min_price: values.min_price,
            max_price: values.max_price,
            property_details: values.property_details,
            specific_property: values.specific_property,
            business_opportunity: values.business_opportunity,
            notes: values.notes,
            expected_closing_date: formattedDate,
            closing_price: values.closing_price
        };

        try {
            await editAreaOfInterestInfoById(dealData.id, body);

            // Update local state with new values
            setDealData((prev) => ({
                ...prev,
                city: values.city,
                neighborhood: values.neighborhood,
                propertyType: values.property_type,
                numberOfBedrooms: values.number_of_bedrooms,
                numberOfBathrooms: values.number_of_bathrooms,
                minPrice: values.min_price,
                maxPrice: values.max_price,
                propertyDetails: values.property_details,
                specificProperty: values.specific_property,
                businessOpportunity: values.business_opportunity,
                notes: values.notes,
                expectedClosingDate: formattedDate,
                closingPrice: values.closing_price
            }));
            setEditMode(false);
            openNotification("Update deal successfully", "Success")
        } catch (error) {
            console.error(error);
            openNotification("Can't Edit the Deal", "Error");
        }
    };

    const openModalWithPrefill = (type) => {
        setModalType(type);

        // Pre-fill with the existing values if any
        formRef.current?.setFieldsValue({
            specific_property: dealData.specificProperty ?? "",
            expected_closing_date: dealData.expectedClosingDate
                ? moment(dealData.expectedClosingDate, "YYYY-MM-DD")
                : null,
            closing_price: dealData.closingPrice ?? ""
        });

        setClosingInfoModal(true);
    };

    const forwardStep = async () => {
        const nextStatus = getNextStatus(); // e.g. "contingent", "pending", or "closed"

        // If the next status requires us to ask for closing info:
        if (dealData.status === "ACTIVE" && (nextStatus === "contingent" || nextStatus === "pending")) {
            openModalWithPrefill("expected");
            return; // Don’t increment step yet
        }

        // If the status changes from pending to closed => ask for final closing info
        if (dealData.status === "PENDING" && nextStatus === "closed") {
            openModalWithPrefill("final");
            return;
        }

        // Otherwise, if no modal is needed, just increment
        try {
            const updatedDeal = await incrementAreaOfInterest(dealData.id);
            setDealData((prev) => ({
                ...prev,
                progressStep: updatedDeal.progress_step,
                status: updatedDeal.deal_status,
                lastProceedDate: updatedDeal.last_proceed_date,
                representationDate: updatedDeal.client_representation_date
            }));
        } catch (error) {
            console.error(error);
            openNotification("Unable to move forward", "Error");
        }
    };

    const backwardStep = async () => {
        try {
            const updatedDeal = await decrementAreaOfInterest(dealData.id);
            setDealData((prev) => ({
                ...prev,
                progressStep: updatedDeal.progress_step,
                status: updatedDeal.deal_status,
                representationDate: updatedDeal.client_representation_date
            }));
        } catch (error) {
            console.error(error);
            openNotification("Unable to move backward", "Error");
        }
    };

    const handleOk = async () =>{
        try {
            await formRef.current.validateFields(); // Validate fields before submission
            formRef.current.submit();
        } catch (error) {
            console.error("Validation failed:", error);
        }
    }

    const submitForm = async (values) => {
        setLoading(true);
        try {
            const dateFormatted = values.expected_closing_date
                ? values.expected_closing_date.format("YYYY-MM-DD")
                : null;

            // Build the request body
            const body = {
                specific_property: values.specific_property,
                expected_closing_date: dateFormatted,
                closing_price: parseFloat(values.closing_price),
            };

            await editAreaOfInterestInfoById(dealData.id, body);

            // After updating, increment the step
            const updatedDeal = await incrementAreaOfInterest(dealData.id);

            // Update local state with new step + new data
            setDealData((prev) => ({
                ...prev,
                progressStep: updatedDeal.progress_step,
                status: updatedDeal.deal_status,
                lastProceedDate: updatedDeal.last_proceed_date,
                specificProperty: values.specific_property,
                expectedClosingDate: dateFormatted,
                closingPrice: parseFloat(values.closing_price)
            }));

            // Reset modal
            setClosingInfoModal(false);
            setModalType(null);
            formRef.current.resetFields();

        } catch (error) {
            console.error("Submission error:", error);
            openNotification("Unable to update closing info", "Error");
        } finally {
            setLoading(false);
        }
    };

    // If we have no deal or no ID, we might still be loading or something's missing
    if (!dealData || !dealData.id) {
        return (
            <div style={{ padding: "20px" }}>
                <Button type="default" onClick={() => navigate(-1)}>Back</Button>
                <Divider />
                <h2>Deal is not available</h2>
            </div>
        );
    }

    const readOnlyView = (
        <>
            <Divider className="divider" orientation="left">Property Info</Divider>
            <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
                <Col span={8}>
                    <h1>
                        City: &nbsp;<span className="text">{dealData.city || "N/A"}</span>
                    </h1>
                </Col>
                <Col span={8}>
                    <h1>
                        Neighborhood: &nbsp;<span className="text">{dealData.neighborhood || "N/A"}</span>
                    </h1>
                </Col>
                <Col span={8}>
                    <h1>
                        Property Type: &nbsp;<span className="text">{dealData.propertyType || "N/A"}</span>
                    </h1>
                </Col>
            </Row>

            <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
                <Col span={8}>
                    <h1>
                        Bedroom(s): &nbsp;<span className="text">{dealData.numberOfBedrooms || "N/A"}</span>
                    </h1>
                </Col>
                <Col span={8}>
                    <h1>
                        Bathroom(s): &nbsp;<span className="text">{dealData.numberOfBathrooms || "N/A"}</span>
                    </h1>
                </Col>
                <Col span={8}>
                    <h1>
                        Price Range: &nbsp;
                        <span className="text border">{dealData.minPrice || 0}</span>
                        <span> &nbsp;- &nbsp;</span>
                        <span className="text border">{dealData.maxPrice || "∞"}</span>
                    </h1>
                </Col>
            </Row>

            <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
                <Col>
                    <h1>
                        Property Details: &nbsp;
                        <span className="text">{dealData.propertyDetails || "N/A"}</span>
                    </h1>
                </Col>
            </Row>

            <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
                <Col span={12}>
                    <h1>
                        Specific Property: &nbsp;
                        <span className="text">{dealData.specificProperty || "N/A"}</span>
                    </h1>
                </Col>
                <Col span={12}>
                    <h1>
                        Business Opportunity: &nbsp;
                        <span className="text">{dealData.businessOpportunity || "N/A"}</span>
                    </h1>
                </Col>
            </Row>

            {/* Transaction Info Section */}
            <Divider className="divider" orientation="left">Transaction Info</Divider>
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <h1>
                        Expected Closing Date:&nbsp;
                        <span className="text">{dealData.expectedClosingDate || "N/A"}</span>
                    </h1>
                </Col>
                <Col span={12}>
                    <h1>
                        Closing Price:&nbsp;
                        <span className="text">{dealData.closingPrice ? readableNumberFormat(dealData.closingPrice) : "N/A"}</span>
                    </h1>
                </Col>
            </Row>

            <Divider className="divider" orientation="left">Note</Divider>
            <Row>
                <Col>
                    <h1>
                        Notes: &nbsp;
                        <span className="text">{dealData.notes || "N/A"}</span>
                    </h1>
                </Col>
            </Row>
        </>
    );

    const editView = (
        <Form form={form} layout="vertical" onFinish={updateDeal}>
            <Divider className="divider" orientation="left">Property Info</Divider>
            <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
                <Col span={12}>
                    <div className="inline-input-container">
                        <h1 className="label">City:&nbsp;</h1>
                        <Form.Item name="city" style={{ marginBottom: 0, flex: 1 }}>
                            <Input disabled={dealData.status === "CLOSED"}/>
                        </Form.Item>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="inline-input-container">
                        <h1 className="label">Neighborhood:&nbsp;</h1>
                        <Form.Item name="neighborhood" style={{ marginBottom: 0, flex: 1 }}>
                            <Input disabled={dealData.status === "CLOSED"}/>
                        </Form.Item>
                    </div>
                </Col>
            </Row>

            <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
                <Col flex="auto">
                    <div className="inline-input-container">
                        <h1 className="label">Property Type:&nbsp;</h1>
                        <Form.Item name="property_type" style={{ marginBottom: 0, flex: 1 }}>
                            <Input disabled={dealData.status === "CLOSED"}/>
                        </Form.Item>
                    </div>
                </Col>
            </Row>

            <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
                <Col flex="auto">
                    <div className="inline-input-container">
                        <h1 className="label">Bedroom(s):&nbsp;</h1>
                        <Form.Item name="number_of_bedrooms" style={{ marginBottom: 0, flex: 1 }}>
                            <Input disabled={dealData.status === "CLOSED"}/>
                        </Form.Item>
                    </div>
                </Col>
                <Col flex="auto">
                    <div className="inline-input-container">
                        <h1 className="label">Bathroom(s):&nbsp;</h1>
                        <Form.Item name="number_of_bathrooms" style={{ marginBottom: 0, flex: 1 }}>
                            <Input disabled={dealData.status === "CLOSED"}/>
                        </Form.Item>
                    </div>
                </Col>
            </Row>

            <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
                <Col flex="auto">
                    <div className="inline-input-container">
                        <h1 className="label">Price Range:&nbsp;</h1>
                        <Form.Item name="min_price" style={{ marginBottom: 0, width: "100%" }} rules={[{ required: true, message: 'Minimum price is required' }]}>
                            <Input style={{ width: "100%" }} disabled={dealData.status === "CLOSED"}/>
                        </Form.Item>
                        &nbsp;-&nbsp;
                        <Form.Item name="max_price" style={{ marginBottom: 0, width: "100%" }} rules={[{ required: true, message: 'Maximum price is required' }]}>
                            <Input style={{ width: "100%" }} disabled={dealData.status === "CLOSED"}/>
                        </Form.Item>
                    </div>
                </Col>
            </Row>

            <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
                <Col flex="auto">
                    <div className="inline-input-container">
                        <h1 className="label">Property Details: &nbsp;</h1>
                        <Form.Item name="property_details" style={{ marginBottom: 0, flex: "auto" }}>
                            <Input.TextArea rows={6} disabled={dealData.status === "CLOSED"}/>
                        </Form.Item>
                    </div>
                </Col>
            </Row>

            <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
                <Col flex="auto">
                    <div className="inline-input-container">
                        <h1 className="label">Specific Property: &nbsp;</h1>
                        <Form.Item name="specific_property" extra="Please include unit number if applicable." style={{ marginBottom: 0, flex: "auto" }}>
                            <Input.TextArea rows={4} disabled={dealData.status === "CLOSED"}/>
                        </Form.Item>
                    </div>
                </Col>
            </Row>

            <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
                <Col flex="auto">
                    <div className="inline-input-container">
                        <h1 className="label">Business Opportunity: &nbsp;</h1>
                        <Form.Item name="business_opportunity" style={{ marginBottom: 0, flex: "auto" }}>
                            <Input.TextArea rows={4} disabled={dealData.status === "CLOSED"}/>
                        </Form.Item>
                    </div>
                </Col>
            </Row>

            {/* Transaction Info Section */}
            <Divider className="divider" orientation="left">Transaction Info</Divider>
            <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
                <Col span={12}>
                    <div className="inline-input-container">
                        <h1 className="label">Expected Closing Date: &nbsp;</h1>
                        <Form.Item
                            name="expected_closing_date"
                            style={{ marginBottom: 0, flex: "auto" }}
                        >
                            <DatePicker format="YYYY-MM-DD" disabled={dealData.status === "CLOSED"}/>
                        </Form.Item>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="inline-input-container">
                        <h1 className="label">Closing Price: &nbsp;</h1>
                        <Form.Item name="closing_price" style={{marginBottom: 0, flex: "auto"}}>
                            <Input disabled={dealData.status === "CLOSED"}/>
                        </Form.Item>
                    </div>
                </Col>
            </Row>

            <Divider className="divider" orientation="left">Note</Divider>
            <Row>
                <Col flex="auto">
                    <div className="inline-input-container">
                        <h1 className="label">Notes: &nbsp;</h1>
                        <Form.Item name="notes" style={{marginBottom: 0, flex: "auto" }}>
                            <Input.TextArea rows={10} />
                        </Form.Item>
                    </div>
                </Col>
            </Row>

            <Row>
                <Button type="primary" htmlType="submit" className="submitButton">
                    Save
                </Button>
            </Row>
        </Form>
    );

    let stagnantDays = getDaysStagnant(dealData.lastProceedDate);

    return (
        <>
            <Button type="default" onClick={() => navigate(-1)}>Back</Button>
            <Divider />
            <div className="header-container">
                <h1>
                    Client Type: &nbsp;&nbsp; <span className="text">{dealData.clientType || "N/A"}</span>
                </h1>
                <Button type="primary" className="editDeal" onClick={toggleEditMode}>
                    {editMode ? "Cancel Edit" : "Edit Deal"}
                </Button>
            </div>

            <div className="page-container">
                {/* Info Section */}
                <div className="left-section">
                    <Divider className="divider" orientation="left">Basic Info</Divider>
                    <Row gutter={[60, 16]} align="middle">
                        <Col span={12}>
                            <h1>
                                Status: &nbsp; <span className="text">{dealData.status || "N/A"}</span>
                            </h1>
                        </Col>
                        <Col span={12}>
                            <h1>
                                Progress Step: &nbsp;
                                <span className="text">{dealData.progressStep ?? 1}</span>
                            </h1>
                        </Col>
                        <Col span={12}>
                            <h1>
                                Starting Date: &nbsp;
                                <span className="text">{dealData.startingDate || "N/A"}</span>
                            </h1>
                        </Col>
                        <Col span={12}>
                            <h1>
                                Representation Date: &nbsp;
                                <span className="text">{dealData.representationDate || "N/A"}</span>
                            </h1>
                        </Col>
                        <Col span={12}>
                            <h1>
                                Days Stagnant: &nbsp;
                                <span className="text">{stagnantDays}</span>
                            </h1>
                        </Col>
                    </Row>

                    {editMode ? editView : readOnlyView}
                </div>

                {/* Progress Section */}
                <div className="right-section">
                    <Collapse defaultActiveKey={["1"]}>
                        <Panel header="Transaction Progress" key="1">
                            <div className="stepButtons">
                                <Tooltip placement="topRight" title="Backward 1 Step" data-testid="step-backward">
                                    <Button onClick={backwardStep}>
                                        <LeftOutlined />
                                    </Button>
                                </Tooltip>

                                <Tooltip placement="topLeft" title="Forward 1 Step">
                                    <Button style={{ marginLeft: "10px" }} onClick={forwardStep} data-testid="step-forward">
                                        <RightOutlined />
                                    </Button>
                                </Tooltip>
                            </div>
                            <DealStep
                                steps={steps}
                                currentStep={dealData.progressStep || 0}
                                dealData={dealData}
                                type="AOI"
                            />
                        </Panel>
                    </Collapse>
                </div>
            </div>

            <Modal
                title={modalType === "expected" ? "Update the closing info" : "Confirm the closing info"}
                open={closingInfo}
                onOk={handleOk}
                onCancel={() => setClosingInfoModal(false)}
                confirmLoading={loading}
            >
                <Form ref={formRef} onFinish={submitForm} layout="vertical">
                    <Form.Item
                        name="specific_property"
                        label="Property Address"
                        extra="Please include unit number, city, state, zip code."
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="expected_closing_date"
                        label={modalType === "expected" ? "Expected Closing Date" : "Closing Date"}
                        rules={[
                            { required: true },
                            {
                                validator: (_, value) => {
                                    if (modalType === "final" && value && value > moment().endOf("day")) {
                                        return Promise.reject(new Error("Closing date cannot be in the future."));
                                    }
                                    return Promise.resolve();
                                },
                            },
                        ]}
                    >
                        <DatePicker
                            format="YYYY-MM-DD"
                            disabledDate={modalType === "final" ? (current) => current && current > moment().endOf("day") : undefined}
                        />
                    </Form.Item>
                    <Form.Item
                        name="closing_price"
                        label={modalType === "expected" ? "Expected Closing Price" : "Closing Price"}
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

export default AreaOfInterestDeal;