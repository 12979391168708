import React, { useState, useEffect, useMemo, useCallback} from "react";
import { useNavigate, useParams } from "react-router-dom";
import {Button,Divider, Form, Space, Table, Tabs, Tag, Typography,notification} from 'antd';
import AddTransactionPopUpWindow from "./AddTransactionPopUpWindow";
import useTableSearch from "../../../Utils/useTableSearch";
import {getClientFilter, getClients, navigateTo} from "../../../Utils/utils";
import {OfficeSelect, SearchByDetail} from "../../../Utils/SearchUtil";

const {Title} = Typography;

function CustomerTable() {
    const {id,role} = useParams()
    const navigate = useNavigate();
    const {getColumnSearchProps} = useTableSearch();
    const [form] = Form.useForm();

    const agentId = id ? id :  localStorage.getItem("userId")
    const agentAccess = localStorage.getItem("agent_role") === "AGENT";
    const fullName = localStorage.getItem("full_name")
    const officeNames = JSON.parse(localStorage.getItem("officeName")) || [];
    const officeId = JSON.parse(localStorage.getItem("officeId")) || [];

    const [clients, setClients] = useState([])
    const [filteredClients, setFilteredClients] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1);

    // Admin
    const [selectedOffice, setSelectedOffice] = useState(null);
    const [isSearch, setIsSearch] = useState(false);
    const [selectedOfficeId, setSelectedOfficeId] = useState(null);

    // active Tab is not necessary if no office should be shown in the table
    const [activeTab, setActiveTab] = useState("officeSearch");

    const fetchClients = useCallback( async () =>{
        setIsLoading(true)
        try {
            const res = await getClients(role, agentId, selectedOfficeId);
            setClients(res);

        } catch(e) {
            if(e.message.includes("401")){
                return
            }
            notification.error({
                message: 'Error',
                description: e.message || 'Something went wrong',
            });
        }
        finally {
            setIsLoading(false);
        }
    }, [role,agentId, selectedOfficeId])

    const handleTableChange = (pagination) => {
        setCurrentPage(pagination.current);
    };

    const navigateToClientDetail = useCallback((clientId, client) => {
        navigate(`/client_detail/${clientId}`, { state: { client } }); // Pass the parameter via URL
    }, [navigate]);

    const onChange = (val) => {
        const officeIndex = officeNames.indexOf(val);
        setSelectedOffice(val)
        if (officeIndex !== -1) {
            setSelectedOfficeId(officeId[officeIndex]);
        }
    }

    const onSearch = async  (val) => {
        let { email, name, phone } = val;
        email = email?.trim();
        name = name?.trim();
        phone = phone?.trim();

        try {
            let res = await getClientFilter(name, email, phone);
            setFilteredClients(res)
            setIsSearch(true);
        } catch (error) {
            notification.error({ message: "Search Error", description: "Failed to fetch clients" });
        }
    }

    useEffect(()=>{
        if (role !== "ADMIN") {
            fetchClients();
        }
        if (role === "ADMIN" && selectedOfficeId) {
            fetchClients();
        }
    }, [role, agentId, selectedOfficeId, fetchClients])

    const sortedClients = useMemo(() => {
        return [...clients]
            .map(client => ({ ...client, key: client.id }))
            .sort((a, b) => b.is_live - a.is_live);
    }, [clients]);

    const sortedFilteredClients = useMemo(() => {
        return [...filteredClients]
            .map(client => ({ ...client, key: client.id }))
            .sort((a, b) => b.is_live - a.is_live);
    }, [filteredClients]);


    const columns = useMemo(() => [
        {
            title: 'Customer Name',
            dataIndex: 'client_name',
            key: 'client_name',
            sorter: (a, b) => a.client_name.localeCompare(b.client_name),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('client_name'),
        },
        ...(agentAccess ? [] : [{
            title: "Agent Name",
            dataIndex: "agent_name",
            key: "agent_name",
            sorter: (a, b) => a.agent_name.localeCompare(b.agent_name),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('agent_name'),
        }]),
        ...(role === "ADMIN" && activeTab === "specificSearch" ? [{
            title: "Office",
            dataIndex: "office_name",
            key: "office_name",
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('office'),
        }] : []),
        {
            title: 'Email',
            dataIndex: 'email',
            sorter: (a, b) => a.email.localeCompare(b.email),
            sortDirections: ['descend', 'ascend'],
            key: 'email',
            ...getColumnSearchProps('email'),
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            ...getColumnSearchProps('phone'),
            render: (_, { phone }) => phone ? phone : "N/A"
        },
        {
            title: 'Registered Date',
            dataIndex: 'date_registered',
            sorter: (a, b) => new Date(a.date_registered) - new Date(b.date_registered),
            sortDirections: ['descend', 'ascend'],
            key: 'date_registered',
        },
        {
            title: 'Status',
            key: 'is_live',
            dataIndex: 'is_live',
            filters: [{ text: 'Live', value: true }, { text: 'Dormant', value: false }],
            onFilter: (value, record) => record.is_live === value,
            render: (_, { is_live }) => (
                <Tag color={is_live ? 'green' : 'gold'}>
                    {is_live ? "Live" : "Dormant"}
                </Tag>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    {role !== "ADMIN" && role !== "OFFICE" && (
                        <AddTransactionPopUpWindow title="Add Transaction" content="Select Transaction Type" client={record} agentId={record.agent_id} />
                    )}
                    <a  href={"/"} onClick={(e) => {
                        e.preventDefault()
                        navigateToClientDetail(record.id, record)
                    }}>Detail</a>
                </Space>
            ),
        },
    ],  [role, activeTab, agentAccess, getColumnSearchProps, navigateToClientDetail]);

    const createClient = () =>{
        return <Button type="primary" style={{ marginBottom:"20px"}} onClick={() => navigateTo(navigate, "/client_register", { id: agentId, fullName })}>
            Add Customer
        </Button>
    }

    const adminClient = () =>{
        return <>
            <Tabs defaultActiveKey="officeSearch" onChange={(key) => setActiveTab(key)}>
                <Tabs.TabPane tab="Search By Office" key="officeSearch">
                    <OfficeSelect officeNames={officeNames} onChange={onChange}/>
                    {selectedOffice && (
                        <div style={{marginTop: "3%"}}>
                            <Divider orientation="left">Customer Table of Office {selectedOffice}</Divider>
                            {clients && (
                                <Table
                                    columns={columns || {}}
                                    dataSource={sortedClients || {}}
                                    pagination={{
                                        current: currentPage,
                                        pageSize: 25,
                                        total: clients.length,
                                        showSizeChanger: false
                                    }}
                                    onChange={handleTableChange}
                                />
                            )}
                        </div>
                    )}

                </Tabs.TabPane>
                <Tabs.TabPane
                    tab="Search By Specific Person"
                    key="specificSearch"
                >
                    <SearchByDetail onSearch={onSearch} form={form}/>
                    <div style={{marginTop: "3%"}}>
                        <Divider orientation="left">Search Result</Divider>
                        {
                            isSearch && (
                                <>
                                    {sortedFilteredClients.length > 0? (
                                        <Table
                                            columns={columns || {}}
                                            dataSource={sortedFilteredClients || {}}
                                            pagination={{
                                                current: currentPage,
                                                pageSize: 25,
                                                total: clients.length,
                                                showSizeChanger: false
                                            }}
                                            onChange={handleTableChange}
                                        />
                                    ) : (
                                        <Title level={3}> No Clients found ! </Title>
                                    )}
                                </>
                            )
                        }

                    </div>
                </Tabs.TabPane>
            </Tabs>
        </>
    }

    const generalRoleClient = () =>{
        return isLoading ? <p>Loading clients...</p> : <div style={{marginTop: "50px"}}>
            <Title>Total Customers: {clients.length}</Title>
            {
                role !== "OFFICE" && (
                   createClient()
                )
            }
            <Table
                columns={columns || []} dataSource={sortedClients || []}
                pagination={{
                    current: currentPage,
                    pageSize: 25,
                    total: clients.length,
                    showSizeChanger: false
                }}
                onChange={handleTableChange}
            />
        </div>
    }

    return (
        <>
            {role === "ADMIN" ? adminClient() : generalRoleClient()}
        </>
    );
}

export default CustomerTable;