import React, {useState, useEffect} from "react";
import {Layout, Button, Space, Menu} from "antd";
import {Outlet, useNavigate,useLocation} from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import "./BasicLayout.css";
import {useAuth} from "../Utils/AuthContext";
import PE_logo from "../../logo/PE-1.png";
import Sync_logo from "../../logo/Syncrocy-3.png";
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    ContactsOutlined,
    HomeOutlined,
    UserOutlined,
    DollarCircleOutlined,
    SolutionOutlined,
    BankOutlined,
    GlobalOutlined
} from '@ant-design/icons';

const {Header, Content, Sider} = Layout;

// The menu items for each role
const menuMap = {
    AGENT: [
        {
            label: "Dashboard",
            icon: <HomeOutlined />,
            key: "/",
        },
        {
            label: "Customers",
            icon: <ContactsOutlined />,
            key: "",
        },
        {
            label: "Transactions",
            icon: <DollarCircleOutlined />,
            children: [
                {
                    key: "",
                    label: "Live",
                },
                {
                    key: "",
                    label: "Completed",
                },
                {
                    key: "",
                    label: "Cancelled",
                },
            ],
        },
        {
            label: (
                <a
                    href="https://www.brokersumo.com/login"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Broker Sumo
                </a>
            ),
            icon: <GlobalOutlined />,
            key: "brokersumo",
        },
    ],
    MANAGER: [
        {
            label: "Dashboard",
            icon: <HomeOutlined />,
            key: "/",
        },
        {
            label: "Agents",
            icon: <UserOutlined />,
            key: "",
        },
        {
            label: "Customers",
            icon: <ContactsOutlined />,
            key: "",
        },
        {
            label: "Transactions",
            icon: <DollarCircleOutlined />,
            children: [
                {
                    key: "",
                    label: "Live",
                },
                {
                    key: "",
                    label: "Completed",
                },
                {
                    key: "",
                    label: "Cancelled",
                },
            ],
        },
        {
            label: (
                <a
                    href="https://www.brokersumo.com/login"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Broker Sumo
                </a>
            ),
            icon: <GlobalOutlined />,
            key: "brokersumo",
        },
    ],
    OFFICE: [
        {
            label: "Dashboard",
            icon: <HomeOutlined />,
            key: "/",
        },
        {
            label: "Managers",
            icon: <SolutionOutlined />,
            key: "",
        },
        {
            label: "Agents",
            icon: <UserOutlined />,
            key: "",
        },
        {
            label: "Customers",
            icon: <ContactsOutlined />,
            key: "",
        },
        {
            label: "Transactions",
            icon: <DollarCircleOutlined />,
            children: [
                {
                    key: "",
                    label: "Live",
                },
                {
                    key: "",
                    label: "Completed",
                },
                {
                    key: "",
                    label: "Cancelled",
                },
            ],
        },
        {
            label: (
                <a
                    href="https://www.brokersumo.com/login"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Broker Sumo
                </a>
            ),
            icon: <GlobalOutlined />,
            key: "brokersumo",
        },
    ],
    ADMIN: [
        {
            label: "Dashboard",
            icon: <HomeOutlined />,
            key: "/",
        },
        {
            label: "Offices",
            icon: <BankOutlined />,
            key: "",
        },
        {
            label: "Managers",
            icon: <SolutionOutlined />,
            key: "",
        },
        {
            label: "Agents",
            icon: <UserOutlined />,
            key: "",
        },
        {
            label: "Customers",
            icon: <ContactsOutlined />,
            key: "",
        },
        {
            label: "Transactions",
            icon: <DollarCircleOutlined />,
            children: [
                {
                    key: "",
                    label: "Live",
                },
                {
                    key: "",
                    label: "Completed",
                },
                {
                    key: "",
                    label: "Cancelled",
                },
            ],
        },
        {
            label: (
                <a
                    href="https://www.brokersumo.com/login"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Broker Sumo
                </a>
            ),
            icon: <GlobalOutlined />,
            key: "brokersumo",
        },
    ],
};

function BasicLayout() {
    const location = useLocation();
    const { authed, handleLogOut } = useAuth();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [collapsed, setCollapsed] = useState(false);

    // Now we read agentRole and agentId inside the component
    const agentRole = localStorage.getItem("agent_role");
    const agentId = localStorage.getItem("userId");
    const officeId = localStorage.getItem("officeId");

    // Get first/last name for Agent/Manager/Admin, or full office name
    const firstName = localStorage.getItem("first_name") || "";
    const lastName = localStorage.getItem("last_name") || "";
    const officeFullName = localStorage.getItem("full_name") || "";

    // Build the userInitials logic:
    // - If role is OFFICE: only first letter of full_name
    // - Otherwise: first letter of first_name + first letter of last_name
    let userInitials = "";
    if (agentRole === "OFFICE") {
        userInitials = officeFullName.trim().charAt(0).toUpperCase() || "O";
    } else {
        const fInitial = firstName.trim().charAt(0).toUpperCase();
        const lInitial = lastName.trim().charAt(0).toUpperCase();
        // If neither firstName nor lastName is set, default to "P"
        userInitials = (fInitial || "") + (lInitial || "");
        if (!userInitials) {
            userInitials = "P";
        }
    }

    // Because we might have sub-routes, store the current path in state
    const [selectedKeys, setSelectedKeys] = useState(location.pathname || "/");

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    const handleTitleClick = () => {
        navigate("/");
    };

    const handleProfileClick = () => {
        navigate("/profile");
    };

    const handleLogout = () => {
        handleLogOut();
        sessionStorage.removeItem("selectedMenu");
        navigate("/");
        queryClient.clear();
    };

    // Build the dynamic menu items for the user’s role, injecting agentId
    const buildMenu = () => {
        if (!agentRole) return [];

        // Copy the role's menu config
        const roleMenu = [...(menuMap[agentRole] || [])];

        // We loop over items to fill in agentId or managerId
        roleMenu.forEach((item) => {
            // If it's top-level with no children
            if (!item.children) {
                if (item.label === "Customers" && agentId) {
                    item.key = `/clients/${agentRole}/${agentId}`;
                } else if (item.label === "Agents" && agentId) {
                    item.key = `/all_agents/${agentRole}/${agentId}`;
                }
                else if(item.label === "Managers" && agentId && agentRole === "ADMIN"){
                    item.key = `/all_managers/${agentId}`;
                }
                else if (item.label === "Managers" && officeId) {
                    item.key = `/all_managers/${officeId}`;
                }
                else if (item.label === "Offices") {

                    item.key = "/all_offices";
                }
            } else {
                // If it has children (the Transactions group)
                item.children.forEach((child) => {
                    if (child.label === "Live") {
                        child.key = `/live_transactions/${agentRole}/${agentId}`;
                    } else if (child.label === "Completed") {
                        child.key = `/history_table/${agentRole}/${agentId}/archived`;
                    } else if (child.label === "Cancelled") {
                        child.key = `/history_table/${agentRole}/${agentId}/cancelled`;
                    }
                });
            }
        });

        return roleMenu;
    };

    const navigation = buildMenu();

    const handleMenuClick = ({key}) => {
        if (key === "brokersumo") return;
        if (key) {
            navigate(key);
            setSelectedKeys(key)
            sessionStorage.setItem('selectedMenu', key);
        }
    };

    useEffect(() => {
        setSelectedKeys(location.pathname);
    }, [location.pathname]);

    useEffect(() => {
        const handleBeforeUnload = () => {
            sessionStorage.removeItem('selectedMenu');
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };

    }, []);

    return (
        <Layout className="layout">
            <Header style={{backgroundColor: "#5F7A66"}}>
                <div className="header-parent">
                    <div className="header-left" onClick={handleTitleClick}>
                        <img src={PE_logo} alt="PE_Logo" className="logo-img"/>
                    </div>

                    <div className="navTitle" onClick={handleTitleClick}>
                        <img src={Sync_logo} alt="Sync_Logo" className="logo-img"/>
                    </div>

                    <div className="header-right">
                        {authed && (
                            <Space>
                                <Button shape="circle" onClick={handleProfileClick}>
                                    {userInitials}
                                </Button>
                                <Button onClick={handleLogout}>Logout</Button>
                            </Space>
                        )}
                    </div>
                </div>
            </Header>

            <Layout>
                {authed && (
                    <Sider
                        width={256}
                        style={{ background: "#fff" }}
                        collapsed={collapsed}
                        trigger={null}
                    >
                        <Button
                            type="primary"
                            onClick={toggleCollapsed}
                            style={{ marginBottom: 16 }}
                        >
                            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        </Button>
                        <Menu
                            selectedKeys={[selectedKeys]}
                            mode="inline"
                            defaultSelectedKeys={["/"]}
                            items={navigation}
                            onClick={handleMenuClick}
                        />
                    </Sider>
                )}

                <Content className="content-container">
                    <Outlet/>
                </Content>

            </Layout>

        </Layout>
    );
}

export default BasicLayout;