import React, {useState, useEffect} from "react";
import LoginForm from "../Forms/LoginForm";
import {useAuth} from "../Utils/AuthContext";
import "./Home.css";
import Manager from "../Agents/Manager";
import Agent from "../Agents/Agent";
import Office from "../Agents/Office";
import Admin from "../Agents/Admin";

function Home() {
    const [authed, setAuthed] = useState(false);
    const {authed: authState} = useAuth();
    const agentRole = localStorage.getItem("agent_role");
    useEffect(() => {
        if (authState) {
            setAuthed(true)
        } else {
            setAuthed(false);
        }
    }, [authState]);

    const onLoginSuccess = (userId) => {
        console.log(userId);
        setAuthed(true);
        localStorage.setItem("userId", userId);
    };

    const switchRole = (role) => {
        switch (role) {
            case "AGENT":
                return <Agent/>;
            case "MANAGER":
                return <Manager/>;
            case "OFFICE":
                return <Office/>;
            case "ADMIN":
                return <Admin/>
            default:
                return <h1>404 Not Found</h1>;
        }
    }

    return (
        <> {authed ? switchRole(agentRole) : (<LoginForm onSuccess={onLoginSuccess}/>)} </>
    );

}

export default Home;