import {Table, Tag} from "antd";
import React from "react";
import {useNavigate} from "react-router-dom";
import useTableSearch from "../Utils/useTableSearch";
import {dealNavigate, getDaysStagnant} from "../Utils/utils";
import {clientTypeFilters, statusFilter, typeColorMapping} from "./LiveTransactionTable";

const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
};

function DealTables({deals, type}) {
    const role = localStorage.getItem("agent_role");
    const {getColumnSearchProps} = useTableSearch();
    const columns = [
        // Conditionally add an office column if ADMIN
        ...(role === "ADMIN"
            ? [
                {
                    title: "Office",
                    dataIndex: "office_name",
                    key: "office_name",
                    width: "10%",
                    sortDirections: ['descend', 'ascend'],
                    ...getColumnSearchProps('office_name'),
                },
            ]
            : []),
        ...(role === "AGENT"
                ? [] : [{
                    title: "Agent Name",
                    dataIndex: "agent_name",
                    key: "agent_name",
                    width: "15%",
                    sorter: (a, b) => a.agent_name.localeCompare(b.agent_name),
                    sortDirections: ['descend', 'ascend'],
                    ...getColumnSearchProps('agent_name'),
                }]
        ),
        {
            title: "Starting Date",
            dataIndex: "starting_date",
            key: "starting_date",
            width: '15%',
            sorter: (a, b) => {
                const dateA = new Date(a["starting_date"]);
                const dateB = new Date(b["starting_date"]);
                return dateA - dateB;
            },
        },
        {
            title: 'Client Type',
            dataIndex: 'client_type',
            width: '10%',
            filters: clientTypeFilters,
            render: (_, {client_type}) => {
                const typeColorMapping = {
                    RENTER: 'blue',
                    BUYER: 'blue',
                    INVESTOR: 'blue',
                    LANDLORD: 'blue',
                    SELLER: 'blue'
                };

                const tagColor = typeColorMapping[client_type] || 'geekblue';
                return (
                    <Tag color={tagColor}>
                        {client_type}
                    </Tag>
                );
            },
            onFilter: (value, record) => record.client_type.startsWith(value),
        },
        {
            title: "Deal Status",
            dataIndex: "deal_status",
            key: "deal_status",
            width: "10%",
            filters: statusFilter,
            onFilter: (value, record) => record.deal_status.startsWith(value),
            render: (_, {deal_status}) => {
                const tagColor = typeColorMapping[deal_status] || 'geekblue';
                return (
                    <Tag color={tagColor}>
                        {deal_status}
                    </Tag>
                );
            }
        },
        {
            title: type === "AOI" ? "Property Address / Area of Interest" : "Property Address",
            dataIndex: "property_address",
            width: "30%",
            sorter: (a, b) => a.property_address.localeCompare(b.property_address),
            ...getColumnSearchProps('property_address'),
        },
        {
            title: "Days Stagnant",
            key: "days_stagnant",
            width: "10%",
            defaultSortOrder: "descend",
            sorter: (a, b) => getDaysStagnant(a.last_proceed_date) - getDaysStagnant(b.last_proceed_date),
            render: (_, record) => getDaysStagnant(record.last_proceed_date),
        },
        {
            title: 'Action',
            key: 'action',
            width: '10%',
            render: (_, record) => (
                <a
                    href={"/"}
                    onClick={(e) => {
                        e.preventDefault();
                        navigateToDealDetail(record.transaction_id, record, false)
                    }}>Detail</a>
            ),
        },
    ];
    const navigate = useNavigate()
    const navigateToDealDetail = (dealId, deal) => {
        const url = dealNavigate(type)
        navigate(url, {
            state: {
                dealId,
                deal
            },
        });
    };

    return (
        <Table columns={columns} dataSource={deals} onChange={onChange}/>
    )
}

export default DealTables;