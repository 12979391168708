import React, {useState} from "react"
import {Row, Col, Button, Modal, Form, Input, notification, Radio} from "antd"
import {createAgentOrManager} from "./utils";

const actions = {
    "AGENT": ["Add Customer", "Add Transaction"],
    "MANAGER": ["Add Customer", "Add Transaction"],
    "OFFICE": [  "Add Agent/Manager", "Assign Agent(s) to manager"]
}

function ActionList({roleType, actionHandlers, office, onSuccess}) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();
    const [, setLoading] = useState(false);

    const openErrorNotification = (message, description) => {
        notification.error({
            message,
            description,
            duration: 4, // Show for 4 seconds
        });
    };

    const openSuccessNotification = () => {
        notification.success({
            message: "Success",
            description: "Agent created successfully!",
            duration: 3,
        });
    };

    const showModal = () => setIsModalOpen(true);

    const handleCancel = () => {
        setIsModalOpen(false);
        form.resetFields();
    };

    // Function to handle submit
    const handleSubmit = async () => {
        try {
            setLoading(true); // Show loading state

            const values = await form.validateFields();
            values.office = office;
            await createAgentOrManager(values);

            openSuccessNotification(); // Show success notification
            setIsModalOpen(false);
            form.resetFields();
            if (onSuccess) {
                onSuccess();
            }
        } catch (error) {
            openErrorNotification("Submission Failed", error.message || "Something went wrong");
        } finally {
            setLoading(false);
        }
    };


    const addAgentModal = () => {
        return (
            <Modal
                title="Create New Agent"
                open={isModalOpen}
                onCancel={handleCancel}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleSubmit}>
                        Submit
                    </Button>,
                ]}
            >
                <Form
                    autoComplete="off"
                    form={form} layout="vertical"
                    initialValues={{agent_role: "AGENT"}}>
                    <Form.Item
                        name="first_name"
                        label="Agent First Name"
                        rules={[{required: true, message: "Please enter the agent's name!"}]}
                    >
                        <Input placeholder="Enter agent's first name"/>
                    </Form.Item>
                    <Form.Item
                        name="last_name"
                        label="Agent Last Name"
                        rules={[{required: true, message: "Please enter the agent's name!"}]}
                    >
                        <Input placeholder="Enter agent's name"/>
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label="Email"
                        rules={[{required: true, type: "email", message: "Please enter a valid email!"}]}
                    >
                        <Input autoComplete="off" placeholder="Enter email"/>
                    </Form.Item>
                    <Form.Item
                        name="phone"
                        label="Phone Number"
                        rules={[
                            {required: true, message: "Please enter your phone number!"},
                            {pattern: /^[0-9]{10}$/, message: "Phone number must be 10 digits!"},
                        ]}
                    >
                        <Input autoComplete="off"  placeholder="Enter phone number" maxLength={10}/>
                    </Form.Item>
                    <Form.Item
                        name="agent_role"
                        label="Select Your Role"
                        rules={[{required: true, message: "Please select a role!"}]}
                    >
                        <Radio.Group>
                            <Radio value="AGENT">Agent</Radio>
                            <Radio value="MANAGER">Manager</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[{required: true, message: "Please enter your password!"}]}
                    >
                        <Input.Password autoComplete="new-password" placeholder="Enter your password"/>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }

    const action = actions[roleType]
    const enhancedActionHandlers =  roleType === "OFFICE" ? [showModal, ...actionHandlers] : actionHandlers


    return (<>
        <Row className="row-style" justify="space-evenly">
            {action.map((text, index) => (
                <Col key={index} span={2}>
                    <Button
                        type="primary"
                        className="action-button"
                        onClick={() => enhancedActionHandlers[index]?.()}
                    >
                        {text}
                    </Button>
                </Col>
            ))}
        </Row>
        {addAgentModal()}
    </>)
}


export default ActionList;