import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Divider, Row, notification, Spin, Segmented } from "antd";
import {
    getAgentsByManagerId,
    getClientFullName,
    welcomeStatement,
    navigateTo,
    getAgentById,
    generateSummaryRow,
    getManagerStatistics
} from "../Utils/utils";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import ActionList from "../Utils/ActionList";
import LiveTransactionTable from "../DealTable/LiveTransactionTable";

const keys = ["agent_count", "customer_count", "transaction_count", "pre_transaction_count", "ytd_sales_volume"];
const contents = ["Total Agents", "Total Customers", "Total Live Transactions", "Total Preliminary Transactions", "YTD Sales Vol"];

function Manager() {
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();

    // State variables
    const [manager, setManager] = useState(location.state || {}); // Ensure manager is always an object
    const [teamStaticInfo, setTeamStatInfo] = useState({});
    const [statInfo, setStatInfo] = useState({});
    const [toggleValue, setToggleValue] = useState("Team");

    // Check if the current access is self
    const managerAccess = localStorage.getItem("agent_role") === "MANAGER";

    // Extract Manager Info
    const managerId = managerAccess ? localStorage.getItem("userId") : id;
    const fullName = managerAccess ? localStorage.getItem("full_name") : getClientFullName(manager);
    const email = managerAccess ? localStorage.getItem("email") : manager.email;

    // Action Handlers
    const actionHandlers = [
        () => navigateTo(navigate, "/client_register", { id: managerId, fullName }),
        () => navigateTo(navigate, `/clients/MANAGER/${managerId}`)
    ];

    // Notification Handler
    const openNotification = (notificationTitle, notificationContent) => {
        notification.open({
            message: notificationTitle,
            description: notificationContent || "Error",
            onClick: () => {
                console.error(notificationTitle, notificationContent);
            },
        });
    };

    // Fetch Agents Data
    const fetchAgents = useCallback(async () => {
        try {
            let agentClientInfoList = await getAgentsByManagerId(managerId);
            setManager(prev => ({ ...prev, agent_count: agentClientInfoList.length }));
        } catch (error) {
            openNotification("Error Message", "Fail to get all agents, please refresh the page");
        }
    }, [managerId]);


    // Fetch Manager Data
    const fetchManager = useCallback(async () => {
        try {
            const res = await getAgentById(managerId);
            setManager(res);
        } catch (error) {
            openNotification("Error Message", "Fail to get manager info");
        }
    }, [managerId]);

    // Fetch Manager Statistics
    const fetchManagerStatics = useCallback(async () => {
        try {
            const res = await getManagerStatistics(managerId);
            setTeamStatInfo(res);
            setStatInfo(res);
        } catch (error) {
            openNotification("Error Message", "Fail to get manager statistics, please refresh the page");
        }
    }, [managerId]);

    // Fetch data on component mount or when managerId changes
    useEffect(() => {
        fetchAgents();
        fetchManagerStatics();
        fetchManager();
    }, [fetchAgents, fetchManagerStatics, fetchManager]);

    // Compute Stats based on toggleValue
    const computedStatInfo = useMemo(() => {
        if (toggleValue === "Team") return teamStaticInfo;
        if (toggleValue === "Total") {
            let temp = { ...teamStaticInfo };
            for (let i = 1; i < keys.length; i++) {
                temp[keys[i]] += manager?.[keys[i]] || 0;
            }
            return temp;
        }
        return manager;
    }, [toggleValue, teamStaticInfo, manager]);

    // Update state when computedStatInfo changes
    useEffect(() => {
        setStatInfo(computedStatInfo);
    }, [computedStatInfo]);

    const filteredKeys = toggleValue === "Team" ? keys : keys.slice(1);
    const filteredContents = toggleValue === "Team" ? contents : contents.slice(1);

    const MemoizedSegmented = React.memo(({ value, onChange }) => (
        <Segmented
            options={["Team", "Manager", "Total"]}
            value={value}
            onChange={onChange}
            size="large"
        />
    ));

    // JSX
    return (
        <>
            {managerAccess ? welcomeStatement(fullName, "Manager") : welcomeStatement(fullName, "Manager", true, email)}

            <div
                style={{
                    marginTop: "4%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <MemoizedSegmented value={toggleValue} onChange={setToggleValue} />
            </div>

            {manager ? (
                <Row className="row-style" justify="space-evenly">
                    {statInfo ? generateSummaryRow(statInfo, filteredKeys, filteredContents) : <Spin />}
                </Row>
            ) : (
                <Spin />
            )}

            <ActionList roleType="MANAGER" actionHandlers={actionHandlers} />

            <Divider orientation="left" className="divider">Live Transactions</Divider>
            <LiveTransactionTable agentId={managerId} agentRole="MANAGER" />
        </>
    );
}

export default Manager;
