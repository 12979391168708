import {Col, Typography} from "antd";
import React from "react";
import axiosInstance from "./axiosInstance";
const {Title} = Typography;

export const login = async (credentials, role) => {
    let loginUrl = `/agents/login`;

    if (role === "OFFICE") {
        loginUrl = `/offices/login`;
    } else if (role === "ADMIN") {
        loginUrl = `/admins/login`;
    }

    try {
        const response =await axiosInstance.post(loginUrl, credentials);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const createUser = async (data, role) => {
    let url = "" ;
    if (role === "AGENT" || role === "MANAGER") {
        url = `/agents/signup`;
        data.agent_role = role;
    } else if (role === "OFFICE") {
        url = `/offices/create`;
    } else if (role === "ADMIN") {
        url = `/admins/signup`;
    } else {
        throw new Error("Invalid role provided");
    }


    try{
        const response = await axiosInstance.post(url,JSON.stringify(data))
        return response.data
    }catch(error)
    {
        throw error;
    }};

export const getClients = async (agent_role, userId, selectedOffice = null) => {
    let url = "";

    if (agent_role === "AGENT") {
        url = `/clients/get-by-agentId/${userId}`;
    } else if (agent_role === "MANAGER") {
        url = `/clients/get-by-managerId/${userId}`;
    } else if (agent_role === "OFFICE") {
        url = `/clients/get-by-officeId/${userId}`;
    } else if (agent_role === "ADMIN" && selectedOffice) {
        url = `/clients/get-by-officeId/${selectedOffice}`;
    } else {
        throw new Error("Invalid agent role or missing office selection for ADMIN");
    }

    try {
        const response = await axiosInstance.get(url)
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getClientById = async (id) => {
    const url = `/clients/get-by-id/${id}`
   try{
       const response = await axiosInstance.get(url)
       return response.data;
   }catch(error){
       throw error;
   }
};

export const getManagerByOfficeId = async (officeId) =>{
    const url = `/offices/get-managers-info/${officeId}`
    try{
        const response = await axiosInstance.get(url)
        return response.data
    }catch(error){

        throw error;
    }
}

export const getOfficeByAdmin = async () => {
    const url = `/admins/get-offices`
    try{
        const response = await axiosInstance.get(url)
        return response.data;
    }catch(error){

        throw error;

    }

}

export const getTransactionTitle = (type) => {
    if (type === "LANDLORD" || type === "SELLER"){
        return "Subject of Property Form";
    }
    return  "Area of Interest Form";
}

export function getClientFullName(client) {
    if (client.first_name || client.last_name) {
        return `${client.first_name ?? ''} ${client.last_name ?? ''}`.trim();
    }
    if (client.client_name) {
        return client.client_name;
    }
    if (client.firstName || client.lastName) {
        return `${client.firstName ?? ''} ${client.lastName ?? ''}`.trim();
    }
    return "Unknown Client";
}
export const formLayout = {
    labelCol: {
        span: 40,
    },
    wrapperCol: {
        span: 40,
    },
};

export const getAreaOfInterestByClientId = async (id)=>{
    const url = `/area-of-interests/get-by-clientId/${id}`
    try {
        const response = await axiosInstance.get(url)
        return response.data;
    } catch (error) {

        throw error;
    }
}

export const getSubjectPropertyByClientId = async (id)=>{
    const url =  `/subject-properties/get-by-clientId/${id}`
    try {
        const response = await axiosInstance.get(url)
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getArchivedDealByClientId = async (id)=>{
    const url = `/archived-deals/get-by-clientId/${id}`
    try {
        const response = await axiosInstance.get(url)
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getArchivedDealByTime = async (id, agentRole, startDate, endDate) => {
    let url = "";
    const validatedStartDate = startDate === null ? "" : startDate;
    const validatedEndDate = endDate === null ? "" : endDate;

    if (agentRole === "MANAGER" || agentRole === "AGENT") {
        url = `/archived-deals/get-by-agentId/${id}?start_date=${validatedStartDate}&end_date=${validatedEndDate}`;
    } else if (agentRole === "OFFICE" || agentRole === "ADMIN") {
        url = `/archived-deals/get-by-officeId/${id}?start_date=${validatedStartDate}&end_date=${validatedEndDate}`;
    }

    try {
        const response = await axiosInstance.get(url)
        return response.data;
    } catch (error) {
        throw error;
    }

}

export const getCancelledDealByTime = async  (id, agentRole, startDate, endDate) =>{
    let url = "";
    const validatedStartDate = startDate === null ? "" : startDate;
    const validatedEndDate = endDate === null ? "" : endDate;

    if (agentRole === "MANAGER" || agentRole === "AGENT") {
        url = `/cancelled-deals/get-by-agentId/${id}?start_date=${validatedStartDate}&end_date=${validatedEndDate}`;
    } else if (agentRole === "OFFICE" || agentRole === "ADMIN") {
        url = `/cancelled-deals/get-by-officeId/${id}?start_date=${validatedStartDate}&end_date=${validatedEndDate}`;
    }

    try{
        const response = await axiosInstance.get(url)
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getCancelledDealByClientId = async (id) =>{
    const url = `/cancelled-deals/get-by-clientId/${id}`
    try {
        const response = await axiosInstance.get(url)
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const editAreaOfInterestInfoById = async (id, body) =>{
    const url = `/area-of-interests/update/${id}`
    try{
        const response = await axiosInstance.put(url, body)
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const editSubjectPropertyInfoById = async (id, body) => {
    const url = `/subject-properties/update/${id}`
    try{
        const response = await axiosInstance.put(url, body)
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const incrementAreaOfInterest = async (id) =>{
    const url = `/area-of-interests/${id}/progress-step/increment`
    try{
        const response = await axiosInstance.patch(url)
        return response.data;
    } catch (error) {
        throw error;
    }


}

export const decrementAreaOfInterest = async (id) =>{
    const url = `/area-of-interests/${id}/progress-step/decrement`
    try{
        const response = await axiosInstance.patch(url)
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const cancelAreaOfInterest = async (id, values) =>{
    const body = {
        reason: values.reason,
        date_of_inactivity: values.date_of_inactivity.format("YYYY-MM-DD")
    };

    const url = `/area-of-interests/cancel/${id}`

    try{
        const response = await axiosInstance.post(url, body)
        return response.data;
    } catch (error) {
        throw error;
    }

}

export const archivedAreaOfInterest = async (id) =>{
    const url = `/area-of-interests/archive/${id}`
    try{
        const response = await axiosInstance.post(url)
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const incrementSubjectOfProperty = async (id) =>{
    const url = `/subject-properties/${id}/progress-step/increment`
    try{
        const response = await axiosInstance.patch(url)
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const decrementSubjectOfProperty = async (id) =>{
    const url = `/subject-properties/${id}/progress-step/decrement`
    try{
        const response = await axiosInstance.patch(url)
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const cancelSubjectOfProperty = async (id, values) =>{
    const body = {
        reason: values.reason,
        date_of_inactivity: values.date_of_inactivity.format("YYYY-MM-DD")
    };

    const url = `/subject-properties/cancel/${id}`
    try{
        const response = await axiosInstance.post(url, body)
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const archivedSubjectOfProperty = async (id) =>{
    const url = `/subject-properties/archive/${id}`
    try{
        const response = await axiosInstance.post(url)
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getAgentsByManagerId = async (managerId) =>{
    const url =`/agents/manager/${managerId}`
    try{
        const response = await axiosInstance.get(url)
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getAgentByOfficeId = async (officeId) =>{
    const url = `/offices/get-agents/${officeId}`

    try{
        const response = await axiosInstance.get(url)
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const dealNavigate =(type) =>{
    let url;
    if(type === "AOI"){
        url = `/aoi_deal_detail`;
    }
    else{
        url = `/sop_deal_detail`;
    }
    return url;
}

export const getAgentById = async (agentId) =>{
    const url =`/agents/${agentId}`
    try{
        const response = await axiosInstance.get(url)
        return response.data;
    } catch (error) {
        throw error;
    }

}

export const getLiveTransactions = async (userId, agentRole) => {
    let url = "";
    if (agentRole === "MANAGER") {
       url = `/transactions/manager/${userId}`;
    } else if (agentRole === "AGENT") {
        url = `/transactions/agent/${userId}`;
    } else if (agentRole === "OFFICE") {
        url = `/transactions/office/${userId}`;
    }

    try{
        const response = await axiosInstance.get(url)
        return response.data;
    } catch (error) {
        throw error;
    }

}

export const getManagerNameByOfficeId = async (officeId) =>{

    const url = `/offices/get-manager-names/${officeId}`
    try{
        const response = await axiosInstance.get(url)
        return response.data;
    } catch (error) {
        throw error;
    }

}

export const getSOPTransaction = async (transactionId) =>{
    const url = `/subject-properties/${transactionId}`
    try{
        const response = await axiosInstance.get(url)
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getAOITransaction = async (transactionId) =>{
    const url = `/area-of-interests/${transactionId}`
    try{
        const response = await axiosInstance.get(url)
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const assignAgentToManager = async (agents, managerId) =>{
    const url = `/agents/assign-manager/${managerId}`

    try{
        const response = await axiosInstance.post(url, agents)
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const createAgentOrManager = async (newAgent) =>{
    const url = `/agents/signup`

    try{
        const response = await axiosInstance.post(url, newAgent)
        return response.data;
    } catch (error) {
        throw error;
    }

}

export const getOfficeById = async (officeId) => {
    const url = `/offices/${officeId}`
    try{
        const response = await axiosInstance.get(url)
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getAdminById = async (adminId) => {
    const url = `/admins/${adminId}`
    try{
        const response = await axiosInstance.get(url)
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getTotalStats = async () => {
    const url = `/admins/total-stats`
    try {
        const response = await axiosInstance.get(url)
        return response.data;
    } catch (error) {
        throw error;
    }

}

export const generateSummaryRow = (agentInfo, key, content) => {
    return content.map((text, index) => {
        const value = agentInfo[key[index]] ? agentInfo[key[index]] : 0;

        // Conditionally apply readableNumberFormat for ytd_sales_volume
        const formattedValue = key[index] === "ytd_sales_volume" ? readableNumberFormat(value) : value;

        return (
            <Col span={4} className="summary-col" key={index}>
                <Title level={4} className="summary-content">{formattedValue}</Title>
                <div className="summary-content">{content[index]}</div>
            </Col>
        );
    });
};

export const navigateTo = (navigate, path, state = {}) => {
    navigate(path, {state:state})
}

export const welcomeStatement = (name, role, higherAccess= false, email="") =>{
    return (<>
        <div className="title-container">

            {higherAccess ? null: <Title level={2}>Welcome to the Customer Strategy System &trade;</Title> }
            {higherAccess ? null: <Title level={4}>{role} Dashboard</Title>}
            <Title level={4}>{role} Name: {name} </Title>
            {higherAccess ?  <Title level={4}>Email: {email}</Title> : null}
        </div>
    </>)
}

export const getDaysStagnant = (lastProceedDate) => {
    if (!lastProceedDate) return 0;
    const now = new Date();
    const lastDate = new Date(lastProceedDate);
    const diffTime = now - lastDate;
    return Math.floor(diffTime / (1000 * 60 * 60 * 24));
};

export const readableNumberFormat = (num) => {
    const numericValue = Number(num) || 0;
    return '$' + numericValue.toLocaleString("en-US");
};

export const calculateTimePeriod = (options = []) => {
    const now = new Date();
    let startDate = new Date(now); // Default to now

    function formatDate(date) {
        return date ? date.toISOString().split('T')[0] : null;
    }

    if (options.length === 0) {
        return { startDate: null, endDate: formatDate(now) }; // Default to all transactions if no options are selected
    }

    let selectedOption = options[0]; // Process only the first option if necessary

    switch (selectedOption) {
        case 'Last 24 Hours':
            startDate.setDate(now.getDate() - 1);
            break;
        case 'Last Week':
            startDate.setDate(now.getDate() - 7);
            break;
        case 'Last Month':
            startDate.setMonth(now.getMonth() - 1);
            break;
        case 'All Transactions':
            startDate = null;
            break;
        default:
            throw new Error("Not a valid parameter")
    }

    return { startDate: formatDate(startDate), endDate: formatDate(now) };
}

export const getManagerStatistics = async (managerId) =>{
    const url = `/agents/manager-statistics/${managerId}`
    try{
        const response = await axiosInstance.get(url)
        return response.data;
    } catch (error) {
        throw error;
    }

}

export const getClientFilter = async (name, email, phone) =>{
    const queryParams = new URLSearchParams();

    if (name) queryParams.append("name", name);
    if (email) queryParams.append("email", email);
    if (phone) queryParams.append("phone", phone);

    const url = `/admins/find-client?${queryParams.toString()}`;

    try{
        const response = await axiosInstance.get(url)
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getAgentFilter = async (name, email, phone) => {
    const queryParams = new URLSearchParams();

    if (name) queryParams.append("name", name);
    if (email) queryParams.append("email", email);
    if (phone) queryParams.append("phone", phone);

    const url = `/admins/find-agent?${queryParams.toString()}`;
    try{
        const response = await axiosInstance.get(url)
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getManagerFilter = async (name,email, phone) =>{
    const queryParams = new URLSearchParams();

    if (name) queryParams.append("name", name);
    if (email) queryParams.append("email", email);
    if (phone) queryParams.append("phone", phone);

    const url = `/admins/find-manager?${queryParams.toString()}`;

    try{
        const response = await axiosInstance.get(url)
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getCancelledDealFilter = async (office_id, start_date, end_date) => {
    const queryParams = new URLSearchParams();

    if (office_id) queryParams.append("office_id", office_id);
    if (start_date) queryParams.append("start_date", start_date);
    if (end_date) queryParams.append("end_date", end_date);

    const url = `/admins/find-cancelled-deal?${queryParams.toString()}`;

    try{
        const response = await axiosInstance.get(url)
        return response.data;
    } catch (error) {
        throw error;
    }

};

export const getArchivedDealFilter =  async (office_id, start_date, end_date) =>{

    const queryParams = new URLSearchParams();

    if (office_id) queryParams.append("office_id", office_id);
    if (start_date) queryParams.append("start_date", start_date);
    if (end_date) queryParams.append("end_date", end_date);
    const url = `/admins/find-archived-deal?${queryParams.toString()}`;

    try {
        const response = await axiosInstance.get(url)
        return response.data;
    } catch (error) {
        throw error;
    }
}

export const getLiveTransactionFilter = async (office_id, start_date, end_date) =>{
    const queryParams = new URLSearchParams();

    if (office_id) queryParams.append("office_id", office_id);
    if (start_date) queryParams.append("start_date", start_date);
    if (end_date) queryParams.append("end_date", end_date);

    const url = `/admins/find-transactions?${queryParams.toString()}`;

    try{
        const response = await axiosInstance.get(url)
        return response.data;
    } catch (error) {
        throw error;
    }

}

export const updateClient = async (clientId, values) => {
    const url = `/clients/update/${clientId}`;

    try {
        const response = await axiosInstance.put(url, values);
        return response.data;
    }  catch (error) {
        throw error;
    }
};

export const subjectPropertiesFormSubmit = async (values) => {
    const url = `/subject-properties/create`;

    try {
        const response = await axiosInstance.post(url, values);
        return response.data;
    }  catch (error) {
        throw error;
    }
};

export const profilePasswordChange = async (requestValues) =>{
    let url = "";
    const role = requestValues.role;
    const userId = requestValues.user_id;
    if (role === "AGENT" || role === "MANAGER") {
        url = `/agents/change-password/${userId}`;
    } else if (role === "OFFICE") {
        url = `/offices/change-password/${userId}`;
    } else if (role === "ADMIN") {
        url = `/admins/change-password/${userId}`;
    }
    try {
        console.log("sending request, request: ", requestValues)
        const response = await axiosInstance.put(url, requestValues);
        return response.data;
    }  catch (error) {
        throw error;
    }
}

export const profilePhoneChange = async (requestValues) =>{
    let url = "";
    const role = requestValues.role;
    const userId = requestValues.user_id;
    if (role === "AGENT" || role === "MANAGER") {
        url = `/agents/update-phone/${userId}`;
    } else if (role === "OFFICE") {
        url = `/offices/update-phone/${userId}`;
    } else if (role === "ADMIN") {
        url = `/admins/update-phone/${userId}`;
    }
    try {
        console.log("sending request, request: ", requestValues)
        const response = await axiosInstance.put(url, requestValues);
        return response.data;
    }  catch (error) {
        throw error;
    }
}


// Subject of Property Steps
export const LandlordSteps = [
    { step: "1. Client Registration Form and Consultation", status: "preliminary"},
    { step: "2. Comparative Market Analysis (CMA)", status: "preliminary" },
    { step: "3. Listing Presentation", status: "preliminary" },
    { step: "4. Exclusive Rental Listing Agreement and Rental Disclosures", status: "active" },
    { step: "5. Professional photos, possible staging and videography", status: "active" },
    { step: "6. MLS Input", status: "active" },
    { step: "7. Showings / viewing of the Property / Feedback / Adjustments", status: "active" },
    { step: "8. Applications and Review with Ownership", status: "active" },
    { step: "9. Accepted Applicant / Signed Lease / MLS Status Change", status: "pending" },
    { step: "10. Collect Fees / Commissions / Deposits", status: "pending" },
    { step: "11. Final walk thru and property move-in checklist", status: "pending" },
    { step: "12. Move-in Date / Transaction Closed", status: "closed" },
    { step: "13. PE Leasing Commission Form", status: "closed" },
    { step: "14. Mandatory Check Form", status: "closed" },
    { step: "15. Ensure Transaction is Closed out correctly, if MLS deal", status: "closed" },
    { step: "16. Agent(s) Paid", status: "closed" }
];

export const SellerSteps = [
    { step: "1. Client Registration Form and Consultation", status: "preliminary" },
    { step: "2. Comparative Market Analysis (CMA)", status: "preliminary" },
    { step: "3. Listing Presentation", status: "preliminary" },
    { step: "4. Exclusive Right to Sell Listing Agreement and Sales Disclosures", status: "active" },
    { step: "5. Professional photos, possible staging and videography", status: "active" },
    { step: "6. MLS Input", status: "active" },
    { step: "7. Showings / viewing of the Property / Feedback / Adjustments", status: "active" },
    { step: "8. Receive Offers and Review with Seller", status: "active" },
    { step: "9. Accepted Offer / Signed Disclosures / MLS Status Change", status: "contingent" },
    { step: "10. Earnest Money / Attorney Review / Inspection / Contingencies", status: "contingent" },
    { step: "11. Due Diligence Process / MLS Status Changes", status: "pending" },
    { step: "12. PE Commission Statement", status: "pending" },
    { step: "13. Final walk through", status: "pending" },
    { step: "14. Closing Date / Settlement Statement / Commission Check", status: "closed" },
    { step: "15. Mandatory Check Form", status: "closed" },
    { step: "16. Ensure Transaction is Closed out correctly, if MLS deal", status: "closed" },
    { step: "17. Agent(s) Paid", status: "closed" }
];

// Area of Interest Steps
export const RenterSteps =[
    { step: "1. Client Registration Form and Consultation", status: "preliminary" },
    { step: "2. Desired Property Search Results and Commission Research", status: "preliminary" },
    { step: "3. Tenant Presentation", status: "preliminary" },
    { step: "4. Tenant Disclosure/Exclusive Tenant Representation Agreement", status: "active" },
    { step: "5. Prior to showing properties, must discuss commission / rep agreement", status: "active" },
    { step: "6. Schedule Showing and Property Tours", status: "active" },
    { step: "7. Application and Acceptance", status: "active" },
    { step: "8. Lease Agreement", status: "pending" },
    { step: "9. Pay Fees and Set Move-In Date", status: "pending" },
    { step: "10. Final walk thru and property Move-In Checklist, receive keys, schedule move-in, move in", status: "closed" },
    { step: "11. PE Lease Commission Form", status: "closed" },
    { step: "12. Invoice Landlord (on move-in date), Listing Broker or Property Management", status: "closed" },
    { step: "13. Invoice follow up or invoice is not received within 30 days of move-in (situational)", status: "closed" },
    { step: "14. Mandatory Check Form", status: "closed" },
    { step: "15. Ensure Transaction is Closed out correctly, if MLS deal", status: "closed" },
    { step: "16. Agent(s) Paid", status: "closed" }
];

export const BuyerInvestorSteps = [
    { step: "1. Client Registration Form and Consultation", status: "preliminary" },
    { step: "2. Desired Property Search Results and Commission Research", status: "preliminary" },
    { step: "3. Buyer Presentation", status: "preliminary" },
    { step: "4. Exclusive Buyer Representation Agreement", status: "active" },
    { step: "5. Schedule Showing and Property Tours", status: "active" },
    { step: "6. Offer Presentation and Negotiation", status: "active" },
    { step: "7. Accepted Offer / Signed Disclosures and Contract", status: "contingent" },
    { step: "8. Earnest Money Payment / Inspection / Attorney Review", status: "contingent" },
    { step: "9. Due Diligence Process (mortgage, appraisal, title insurance, etc.)", status: "contingent" },
    { step: "10. Clear to Close / Final walk thru", status: "pending" },
    { step: "11. Closing Date / Settlement Statement / Commission Check", status: "closed" },
    { step: "12. Mandatory Check Form", status: "closed" },
    { step: "13. Ensure Transaction is Closed out correctly, if MLS deal", status: "closed" },
    { step: "14. Agent(s) Paid", status: "closed" }
];

export let allSteps = {
    RENTER: RenterSteps,
    BUYER: BuyerInvestorSteps,
    INVESTOR: BuyerInvestorSteps,
    SELLER: SellerSteps,
    LANDLORD: LandlordSteps,
}

export const dateFormat = 'YYYY-MM-DD';