import React, { useEffect, useState, useCallback} from "react";
import {useNavigate, useParams} from 'react-router-dom';
import { Button, Avatar, Row, Col, Typography, Divider, Form, Input, message, Spin, notification} from 'antd';
import "./ClientDetail.css";
import {
    getAreaOfInterestByClientId,
    getArchivedDealByClientId,
    getCancelledDealByClientId,
    getSubjectPropertyByClientId,
    getClientById, getClientFullName,
    updateClient,
} from "../Utils/utils";
import DealTables from "../DealTable/DealTables";
import ArchivedDealTable from "../DealTable/ArchivedDealTable";
import CancelledDealTable from "../DealTable/CancelledDealTable";
import {useAuth} from "../Utils/AuthContext";

const { Title } = Typography;
const size = {
    xs: 24,
    sm: 32,
    md: 40,
    lg: 64,
    xl: 80,
    xxl: 100,
}

const findLatestRole = (AOI, SOP) =>{
    const temp = [...AOI,...SOP]
    return temp.sort((a, b) => new Date(b.date) - new Date(a.date))
}

function ClientDetail() {
    const navigate = useNavigate();

    // Extract the clientId from URL query parameters if needed
    const {id} = useParams()
    const clientId = id

    const { handleLogOut } = useAuth();

    const redirectToLogOut = useCallback(() => {
        handleLogOut();
        sessionStorage.removeItem("selectedMenu");
        navigate("/");
    }, [handleLogOut, navigate]);

    const [clientData, setClientData] = useState( null);
    const [loading, setLoading] = useState(false);

    const [AOI, setAOI] = useState([]);
    const [SOP, setSOP] = useState([]);
    const [archivedDeals, setArchivedDeals] = useState([]);
    const [cancelledDeals, setCancelledDeals] = useState([]);
    const [lastActive, setLastActive] = useState("No Active Role");

    const [editMode, setEditMode] = useState(false);
    const [form] = Form.useForm();

    const phoneNumberRegex = /^(?:\+?1[-.\s]?)?\(?([2-9][0-9]{2})\)?[-.\s]?([2-9][0-9]{2})[-.\s]?([0-9]{4})$/;

    const fetchClientData = useCallback(async () => {
        if (!clientData && clientId) {
            setLoading(true);
            try {
                const fetchedClient = await getClientById(clientId);
                setClientData(fetchedClient);
            } catch (error) {
                if(error.message.includes("401")){
                    redirectToLogOut()
                    return
                }
                notification.error({
                    message: 'Error',
                    description: error.message || 'Something went wrong',
                });
            } finally {
                setLoading(false);
            }
        }
    }, [clientData, clientId, redirectToLogOut]);

    const fetchDeals = useCallback(async () => {
        if (clientData && clientData.id) {
            try {
                const aoiRes = await getAreaOfInterestByClientId(clientData.id);
                const sopRes = await getSubjectPropertyByClientId(clientData.id);
                const archivedDeal = await getArchivedDealByClientId(clientData.id);
                const cancelledDeal = await getCancelledDealByClientId(clientData.id);
                setAOI(aoiRes);
                setSOP(sopRes);
                setArchivedDeals(archivedDeal);
                setCancelledDeals(cancelledDeal);
                const sorted = findLatestRole(aoiRes, sopRes);
                if (sorted.length > 0) {
                    setLastActive(sorted[0].client_type);
                }
            } catch (e) {
                console.log(e);
            }
        }
    }, [clientData]);

    useEffect(() => {
        fetchClientData();
    }, [fetchClientData]);

    useEffect(() => {
        fetchDeals();
    }, [fetchDeals]);

    const toggleEditMode = () =>{
        if (!clientData) return;
        setEditMode(true);
        // Initialize form values with the current client data
        form.setFieldsValue({
            first_name: clientData.firstName,
            last_name: clientData.lastName,
            additional_name: clientData.additionalName,
            email: clientData.email,
            phone: clientData.phone,
            address: clientData.address,
            city: clientData.city,
            state: clientData.state,
            zip: clientData.zip,
            notes: clientData.notes,
            lead_source: clientData.leadSource,
            is_live: clientData.isLive,
        });
    }

    const handleCancelEdit = () => {
        setEditMode(false);
    }

    const onUpdateFinish = async (values) => {
        if (!clientData) return;

        try {
            await updateClient(clientId,values);

            message.success("Client info updated successfully!");

            // Update the local client object to reflect changes
            setClientData(prev => ({
                ...prev,
                firstName: values.first_name,
                lastName: values.last_name,
                additionalName: values.additional_name,
                email: values.email,
                phone: values.phone,
                address: values.address,
                city: values.city,
                state: values.state,
                zip: values.zip,
                notes: values.notes
            }));

            setEditMode(false);
        } catch (error) {
            console.error("Error updating client:", error);
            message.error(`Error updating client info, please try again: ${error.response.data}`);
        }
    }

    if (loading || !clientData) {
        return <Spin style={{ margin: "50px" }} />;
    }

    const getInitials = (client) => {
        const names = [client.firstName, client.lastName].filter(Boolean);
        return names.map(name => name.charAt(0)).join('').toUpperCase();
    };

    // READ-ONLY VIEW
    const readOnlyView = (
        <>
            <Button className="edit" color="default" variant="solid" onClick={toggleEditMode}>
                Edit
            </Button>

            <Row justify="space-between" align="middle">
                <Col flex="250px">
                    <Avatar
                        size={size}
                        style={{ backgroundColor: '#5F7A66', fontSize: '24px' }}
                    >
                        {getInitials(clientData)}
                    </Avatar>
                </Col>
                <Col flex="auto">
                    <Row className="title">Customer Name:</Row>
                    <Row><Title level={4}>{getClientFullName(clientData)}</Title></Row>
                    {clientData.additionalName && (
                        <>
                            <Row className="title">Additional Names:</Row>
                            <Row><Title level={5}>{clientData.additionalName}</Title></Row>
                        </>
                    )}
                </Col>

                <Col flex="auto">
                    <Row className="title">Registered Date:</Row>
                    <Row><Title level={4}>{clientData.dateRegistered}</Title></Row>

                    <Row className="title">Status:</Row>
                    <Row><Title level={5}>{clientData.isLive ? "Live" : "Dormant"}</Title></Row>
                </Col>

                <Col flex="auto">
                    <Row className="title">Last Active Role:</Row>
                    <Row><Title level={4}>{lastActive}</Title></Row>

                    <Row className="title">Lead Source:</Row>
                    <Row><Title level={5}>{clientData.leadSource? clientData.leadSource : 'N/A'}</Title></Row>
                </Col>
            </Row>

            <Divider className="divider" orientation="left">Contact Info</Divider>
            <Row gutter={[16,16]}>
                <Col span={6}>
                    <Row className="title">Phone:</Row>
                    <Row><Title level={4}>{clientData.phone || 'N/A'}</Title></Row>
                </Col>
                <Col span={6}>
                    <Row className="title">Email:</Row>
                    <Row><Title level={4}>{clientData.email}</Title></Row>
                </Col>
            </Row>

            <Divider className="divider" orientation="left">Address</Divider>
            <Row gutter={[16,16]}>
                <Col span={6}>
                    <Row className="title">Address:</Row>
                    <Row><Title level={5}>{clientData.address}</Title></Row>
                </Col>
                <Col span={6}>
                    <Row className="title">City:</Row>
                    <Row><Title level={5}>{clientData.city}</Title></Row>
                </Col>
                <Col span={6}>
                    <Row className="title">State:</Row>
                    <Row><Title level={5}>{clientData.state}</Title></Row>
                </Col>
                <Col span={6}>
                    <Row className="title">Zip:</Row>
                    <Row><Title level={5}>{clientData.zip}</Title></Row>
                </Col>
            </Row>

            <Divider className="divider" orientation="left">Notes</Divider>
            <Title level={5}><span style={{fontWeight: "normal"}}>{clientData.notes? clientData.notes: "No Notes"}</span></Title>

            <Divider className="divider" orientation="left">Area of Interest Transactions</Divider>
            <DealTables deals={AOI} type="AOI"/>

            <Divider className="divider" orientation="left">Subject Property Transactions</Divider>
            <DealTables deals={SOP} type="SOP"/>

            <Divider className="divider" orientation="left">Completed Transactions</Divider>
            <ArchivedDealTable deals={archivedDeals}/>

            <Divider className="divider" orientation="left">Cancelled Transactions</Divider>
            <CancelledDealTable deals={cancelledDeals}/>
        </>
    )

    // EDIT VIEW
    const editView = (
        <Form
            form={form}
            layout="vertical"
            onFinish={onUpdateFinish}
        >
            <Button onClick={handleCancelEdit}>Cancel</Button>
            <Button type="primary" htmlType="submit" style={{marginLeft:"8px"}}>Update</Button>
            <Divider />

            <Row justify="space-between" align="middle">
                <Col flex="250px">
                    <Avatar
                        size={size}
                        style={{ backgroundColor: '#5F7A66', fontSize: '24px' }}
                    >
                        {getInitials(clientData)}
                    </Avatar>
                </Col>
                <Col flex="auto">
                    <Form.Item name="first_name" label="Customer First Name" rules={[{ required: true }]}>
                        <Input style={{ width: '300px' }}  />
                    </Form.Item>
                    <Form.Item name="last_name" label="Customer Last Name" rules={[{ required: true }]}>
                        <Input style={{ width: '300px' }} />
                    </Form.Item>
                    <Form.Item name="additional_name" label="Additional Customer Name">
                        <Input style={{ width: '300px' }} />
                    </Form.Item>
                </Col>

                <Col flex="auto">
                    <Row className="title">Registered Date:</Row>
                    <Row><Title level={4}>{clientData.dateRegistered}</Title></Row>

                    <Row className="title">Status:</Row>
                    <Row><Title level={5}>{clientData.Live ? "Live" : "Dormant"}</Title></Row>
                </Col>

                <Col flex="auto">
                    <Row className="title">Last Active Role:</Row>
                    <Row><Title level={4}>{lastActive}</Title></Row>

                    <Row className="title">Lead Source:</Row>
                    <Row> <Title level={5}>{clientData.leadSource? clientData.leadSource : 'N/A'}</Title></Row>
                </Col>
            </Row>

            <Divider className="divider" orientation="left">Contact Info</Divider>
            <Row gutter={16}>
                <Col span={6}>
                    <Form.Item name="phone" label="Phone" rules={[
                        { required: false },
                        { pattern: phoneNumberRegex, message: 'Phone number is not valid!' }
                    ]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="email" label="Email" rules={[{ required: true, type: 'email' }]}>
                        <Input />
                    </Form.Item>
                </Col>
            </Row>

            <Divider className="divider" orientation="left">Address</Divider>
            <Row gutter={16}>
                <Col span={6}>
                    <Form.Item name="address" label="Address">
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="city" label="City" >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="state" label="State">
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name="zip" label="Zip">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>

            <Divider className="divider" orientation="left">Notes</Divider>
            <Form.Item name="notes" label="Notes">
                <Input.TextArea rows={4} />
            </Form.Item>

            <Divider className="divider" orientation="left">Area of Interest Transactions</Divider>
            <DealTables deals={AOI} type="AOI"/>

            <Divider className="divider" orientation="left">Subject Property Transactions</Divider>
            <DealTables deals={SOP} type="SOP"/>

            <Divider className="divider" orientation="left">Completed Transactions</Divider>
            <ArchivedDealTable deals={archivedDeals}/>

            <Divider className="divider" orientation="left">Cancelled Transactions</Divider>
            <CancelledDealTable deals={cancelledDeals}/>

        </Form>
    )

    return editMode ? editView : readOnlyView;
}

export default ClientDetail;