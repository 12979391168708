import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Tag, DatePicker, Button, Typography, message } from "antd";
import moment from "moment";
import {
    dateFormat,
    calculateTimePeriod,
    getArchivedDealByTime,
    getCancelledDealByTime,
    getArchivedDealFilter,
    getCancelledDealFilter,
} from "../../Utils/utils";
import ArchivedDealTable from "../../DealTable/ArchivedDealTable";
import CancelledDealTable from "../../DealTable/CancelledDealTable";
import { OfficeSelect } from "../../Utils/SearchUtil";

const { CheckableTag } = Tag;
const { RangePicker } = DatePicker;
const { Title } = Typography;

const tagsData = ["Last 24 Hours", "Last Week", "Last Month", "All Transactions"];

function HistoryFilter() {
    const { role, id, type } = useParams();
    const agentRole = role || localStorage.getItem("agent_role");
    const [selectedTags, setSelectedTags] = useState(["Last 24 Hours"]);
    const [selectedDates, setSelectedDates] = useState([]);
    const [deals, setDeals] = useState({});
    const [selectOfficeId, setSelectOfficeId] = useState(null);

    const officeNames = JSON.parse(localStorage.getItem("officeName")) || [];
    const officeId = JSON.parse(localStorage.getItem("officeId")) || [];

    // Add "All" option for ADMIN
    if (agentRole === "ADMIN" && !officeNames.includes("All")) {
        officeNames.unshift("All");
        officeId.unshift(null);
    }

    const fetchDeals = useCallback(async (startDate, endDate, selectedOfficeId = selectOfficeId) => {
        try {
            let response;
            if (type === "archived") {
                response =
                    agentRole === "ADMIN"
                        ? await getArchivedDealFilter(selectedOfficeId, startDate, endDate)
                        : await getArchivedDealByTime(id, role, startDate, endDate);
            } else {
                response =
                    agentRole === "ADMIN"
                        ? await getCancelledDealFilter(selectedOfficeId, startDate, endDate)
                        : await getCancelledDealByTime(id, role, startDate, endDate);
            }
            setDeals(response);
        } catch (error) {
            console.error("Error fetching deals:", error);
        }
    }, [agentRole, id, role, type, selectOfficeId]);

    const handleTagChange = async (tag, checked) => {
        if (checked) {
            setSelectedTags([tag]);
            setSelectedDates([]);
            const { startDate, endDate } = calculateTimePeriod([tag]);
            await fetchDeals(startDate, endDate);
        }
    };

    const handleOfficeChange = (val) => {
        const officeIndex = officeNames.indexOf(val);
        setSelectOfficeId(officeIndex !== -1 ? officeId[officeIndex] : null);
    };

    const handleDateSelection = (dates, dateStrings) => {
        setSelectedDates(dateStrings);
    };

    const handleShowTransactions = useCallback(async () => {
        if (selectedDates.length < 2) {
            message.error("Please select both a start date and an end date.");
            return;
        }
        await fetchDeals(selectedDates[0], selectedDates[1]);
    }, [fetchDeals, selectedDates]);


    useEffect(() => {
        setSelectedDates([]);
        setSelectedTags(["Last 24 Hours"])
        if (agentRole !== "ADMIN") {
            const { startDate, endDate } = calculateTimePeriod(["Last 24 Hours"]);
            fetchDeals(startDate, endDate);
        }
    }, [type, agentRole,fetchDeals]);

    useEffect(() => {
        if (selectedDates.length === 0) {
            const { startDate, endDate } = calculateTimePeriod(selectedTags);
            fetchDeals(startDate, endDate);
        } else {
            handleShowTransactions();
        }
    }, [selectOfficeId, selectedDates.length, selectedTags, fetchDeals, handleShowTransactions]);

    return (
        <>
            {agentRole === "ADMIN" && (
                <OfficeSelect officeNames={officeNames} onChange={handleOfficeChange} defaultSelection={true} />
            )}
            <div className="date-selection-container">
                <div className="tags-container">
                    {tagsData.map((tag) => (
                        <CheckableTag key={tag} checked={selectedTags.includes(tag)} onChange={(checked) => handleTagChange(tag, checked)}>
                            {tag}
                        </CheckableTag>
                    ))}
                </div>
                <div className="date-picker-container">
                    <RangePicker
                        format={dateFormat}
                        value={selectedDates.length === 2 ? [moment(selectedDates[0], dateFormat), moment(selectedDates[1], dateFormat)] : null}
                        onChange={handleDateSelection}
                    />
                    <Button onClick={() => setSelectedDates([])}>Cancel</Button>
                    <Button type="primary" onClick={()=>{
                        setSelectedTags([])
                        handleShowTransactions()
                    }} disabled={selectedDates.length < 2}>
                        Show Transactions
                    </Button>
                </div>
            </div>
            <Title level={4}>
                {type === "archived" ? "Result of completed transactions" : "Result of cancelled transactions"}
            </Title>
            {type === "archived" ? <ArchivedDealTable deals={deals} /> : <CancelledDealTable deals={deals} />}
        </>
    );
}

export default HistoryFilter;
