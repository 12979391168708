import React, { useEffect, useState, useCallback } from 'react';
import { Row, notification, Spin } from "antd";
import {
    generateSummaryRow,
    getAdminById,
    getTotalStats,
    welcomeStatement
} from "../Utils/utils";
import { useLocation, useParams } from "react-router-dom";
import SignupForm from "../Forms/SignupForm";
import OfficeTable from "./Tables/OfficeTable";

const keys = ["office_count", "agent_count", "customer_count", "transaction_count", "pre_transaction_count", "ytd_sales_volume"];
const contents = ["Total Offices", "Total Agents", "Total Customers", "Total Live Transactions", "Total Preliminary Transactions", "Total Sales Vol"];

function Admin() {
    const location = useLocation();
    const { id } = useParams();

    const [admin, setAdmin] = useState(location.state);
    const [statInfo, setStatInfo] = useState({});
    const [loading, setLoading] = useState(true);

    const adminAccess = localStorage.getItem("agent_role") === "ADMIN";
    const adminId = adminAccess ? localStorage.getItem('userId') : id;
    const adminName = adminAccess ? localStorage.getItem('full_name') : admin?.name || "Admin";

    const openNotification = (notificationTitle, notificationContent = "Error") => {
        notification.open({
            message: notificationTitle,
            description: notificationContent,
            onClick: () => console.log('Notification Closed'),
        });
    };

    const fetchAdminInfo = useCallback(async () => {
        try {
            const res = await getAdminById(adminId);
            setAdmin(res);
        } catch (error) {
            openNotification("Error Message", "Failed to fetch Admin Info");
        }
    }, [adminId]);

    const fetchStatsInfo = useCallback(async () => {
        try {
            const res = await getTotalStats();
            setStatInfo(res);
        } catch (error) {
            openNotification("Error Message", "Failed to fetch stats for Admin");
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchAdminInfo();
        fetchStatsInfo();
    }, [fetchAdminInfo, fetchStatsInfo]);

    return (
        <>
            {welcomeStatement(adminName, "Admin")}

            {loading ? (
                <Spin size="large" style={{ display: "block", margin: "20px auto" }} />
            ) : (
                <Row className="row-style" justify="space-evenly">
                    {adminAccess ? generateSummaryRow(statInfo, keys, contents) : <Spin />}
                </Row>
            )}

            <div style={{ marginBottom: "30px" }}>
                <SignupForm onSuccess={fetchStatsInfo} />
            </div>

            <OfficeTable/>
        </>
    );
}

export default Admin;
