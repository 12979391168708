import { Button, Form, Input, DatePicker, Radio, message, Typography, Checkbox } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import {getClientFullName, formLayout, subjectPropertiesFormSubmit} from "../Utils/utils";

const {Title} = Typography;

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
    required: '${label} is required!',
    types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};

const SubjectPropertyForm = ({clientId, type, client, agentId}) => {
    const [formDisable, setFormDisable] = useState(false);
    const [form] = Form.useForm();
    const navigate = useNavigate();


    //find agent id from local storage
    const agent_id = agentId? agentId : localStorage.getItem('userId');

    const onFinish = async (values) => {

        try {
            values["client_id"] = clientId
            values['agent_id'] = agent_id
            values["starting_date"] = values['starting_date'].format('YYYY-MM-DD');
            // axios funcion
            await subjectPropertiesFormSubmit(values);
            message.success("Form submitted successfully!");
            navigate(-1)
        } catch (error) {
            console.log("error: ",error.response.data);
            message.error(`Error submitting form, please try again: ${error.response.data }`);
        }
    };
    return (
        <Form form={form} initialValues={{
            'client_type': type,
        }} {...formLayout} name="nest-messages" onFinish={onFinish} validateMessages={validateMessages}>
            <Title level={2} style={{textAlign: 'center'}}>Subject Property Form</Title>
            <Title level={5}><span>Customer Name:</span> {getClientFullName(client)}</Title>
            <Form.Item
                name="client_type"
                label="Client Type"
                rules={[{required: true, message: 'Please select at least one client type!'}]}
            >
                <Radio.Group defaultValue={type}>
                    <Radio value="SELLER">Seller</Radio>
                    <Radio value="LANDLORD">Landlord</Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item
                name="starting_date"
                label="Starting Date"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <DatePicker/>
            </Form.Item>

            <Form.Item name="address" label="Property Address" extra="Please include unit number if applicable." rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item name="city" label="City" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item name="state" label="State" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item name="zip" label="Zip Code" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item name="notes" label="Notes">
                <Input />
            </Form.Item>

            <Form.Item
                wrapperCol={{
                    ...formLayout.wrapperCol,
                    offset: 40,
                }}
            >
                <Checkbox
                    checked={formDisable}
                    onChange={(e) => setFormDisable(e.target.checked)}
                >
                    Initial Consultation Complete
                </Checkbox>
            </Form.Item>

            <Form.Item
                wrapperCol={{
                    ...formLayout.wrapperCol,
                    offset: 40,
                }}
            >
                {formDisable ? (
                    <Button type="primary" htmlType="submit" >
                        Submit
                    </Button>
                ) : (
                    <Button type="primary" htmlType="submit" disabled>
                        Submit
                    </Button>
                )}
            </Form.Item>

        </Form>
    );
};

export default SubjectPropertyForm;