import React from "react";
import { useLocation } from "react-router-dom";
import "./AddTransaction.css";
import AreaOfInterestForm from "./AreaOfInterestForm";
import { getTransactionTitle } from "../Utils/utils";
import SubjectPropertyForm from "./SubjectPropertyForm";

function AddTransaction() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get("type");
    const id = searchParams.get('id')
    const title = getTransactionTitle(type)
    const client = location.state.client || {}
    const agentId = location.state.agentId
    return (
        <div className="survey-container">
            <div className="survey-form">
                {
                    title === "Area of Interest Form" ? <AreaOfInterestForm clientId={id} type={type} client={client} agentId={agentId}/> : <SubjectPropertyForm clientId={id} type={type} client={client} agentId={agentId}/>
                }
            </div>
        </div>
    );
}

export default AddTransaction;