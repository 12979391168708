import React, {useState} from 'react';
import {Modal, Select} from 'antd';
import {useNavigate} from "react-router-dom";

const AddTransactionPopUpWindow = ({title, content, client, agentId}) => {
    const [selectedValue, setSelectedValue] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);


    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
        navigate(`/add_transaction?type=${selectedValue}&id=${client.id}`, {state: {client: client, agentId: agentId}})
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const handleChange = (value) => {
        setSelectedValue(value); // Save the selected value to state
        console.log("Selected value:", value);
    };

    const navigate = useNavigate()

    return (
        <>
            <button
                onClick={showModal}
                style={{
                    background: "none",
                    border: "none",
                    color: "#1890ff",
                    textDecoration: "none",
                    cursor: "pointer",
                    padding: 0,
                    fontSize: "inherit"
                }}
            >
                {title}
            </button>

            <Modal
                title={content} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
            >
                <Select
                    showSearch
                    style={{width: 450}}
                    placeholder="Select the role of transaction"
                    onChange={handleChange}
                    value={selectedValue}
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={[
                        {
                            value: 'RENTER',
                            label: 'RENTER',
                        },
                        {
                            value: 'LANDLORD',
                            label: 'LANDLORD',
                        },
                        {
                            value: 'BUYER',
                            label: 'BUYER',
                        },
                        {
                            value: 'SELLER',
                            label: 'SELLER',
                        },
                        {
                            value: 'INVESTOR',
                            label: 'INVESTOR',
                        },
                    ]}
                />
            </Modal>
        </>
    );
};
export default AddTransactionPopUpWindow;