import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Row, notification, Divider } from "antd";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import {
    generateSummaryRow,
    getOfficeById,
    navigateTo,
    welcomeStatement
} from "../Utils/utils";

import ActionList from "../Utils/ActionList";
import LiveTransactionTable from "../DealTable/LiveTransactionTable";

const summaryKeys = ["agent_count", "customer_count", "transaction_count", "pre_transaction_count", "ytd_sales_volume"];
const summaryLabels = ["Total Agents", "Total Customers", "Total Live Transactions", "Total Preliminary Transactions", "YTD Sales Vol"];

function Office() {
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();

    const isOfficeAccess = localStorage.getItem("agent_role") === "OFFICE";
    const officeId = isOfficeAccess ? localStorage.getItem('officeId') : id;

    const initialOffice = location.state;
    const [office, setOffice] = useState(initialOffice);

    const officeLocation = isOfficeAccess ? localStorage.getItem('full_name') : office?.name;
    const email = isOfficeAccess ? localStorage.getItem('email') : office?.email;

    const actionHandlers = useMemo(() => [
        () => navigateTo(navigate, `/all_agents/OFFICE/${officeId}`),
    ], [navigate, officeId]);

    const openNotification = useCallback((title, content = "Error") => {
        notification.open({
            message: title,
            description: content,
            onClick: () => console.log('Notification clicked'),
        });
    }, []);

    const fetchOfficeInfo = useCallback(async () => {
        try {
            const data = await getOfficeById(officeId);
            setOffice(data);
        } catch (error) {
            openNotification("Error Message", "Failed to fetch Office Info");
        }
    }, [officeId, openNotification]);

    useEffect(() => {
        fetchOfficeInfo();
    }, [fetchOfficeInfo]);

    return (
        <>
            {welcomeStatement(officeLocation, "Office", !isOfficeAccess, email)}

            {office && (
                <Row className="row-style" justify="space-evenly">
                    {generateSummaryRow(office, summaryKeys, summaryLabels)}
                </Row>
            )}

            <ActionList
                roleType="OFFICE"
                actionHandlers={actionHandlers}
                office={officeLocation}
                onSuccess={fetchOfficeInfo}
            />

            <Divider orientation="left" className="divider">
                Live Transactions
            </Divider>

            <LiveTransactionTable agentId={officeId} agentRole="OFFICE" />
        </>
    );
}

export default Office;
