import { Button, Form, Input, DatePicker, Radio, InputNumber, message, Row, Col, Typography, Checkbox } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { formLayout, getClientFullName } from "../Utils/utils";
import axiosInstance from "../Utils/axiosInstance";

const {Title} = Typography;

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
    required: '${label} is required!',
    types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};

const AreaOfInterestForm = ({clientId, type, client, agentId}) => {
    const navigate = useNavigate();
    const [formDisable, setFormDisable] = useState(false);

    //find agent id from local storage
    const agent_id = agentId? agentId : localStorage.getItem('userId');
    const onFinish = async (values) => {
        try {
            values["client_id"] = clientId
            values['agent_id'] = agent_id
            values["starting_date"] = values['starting_date'].format('YYYY-MM-DD');

            const url = `/area-of-interests/create`
            const response = await axiosInstance.post(url, values)
            const data =  response.data;
            message.success("Form submitted successfully!");
            console.log("Response from server:", data);
            navigate(-1)

        } catch (error) {
            const status = error.response?.status;

            if(status === 401){
                window.location.href = "/";
                localStorage.clear()
                return
            }
            const errorMsg = error.response?.data || error.message;
            message.error(errorMsg);

        }
    };

    return (
        <Form {...formLayout} name="nest-messages" onFinish={onFinish} initialValues={{
            'client_type': type,
        }} validateMessages={validateMessages}>
            <Title level={2} style={{textAlign: 'center'}}>Area Of Interest Form</Title>
            <Title level={5}><span>Customer Name:</span> {getClientFullName(client)}</Title>

            <Form.Item
                name="client_type"
                label="Client Type"
                rules={[{required: true, message: 'Please select at least one client type!'}]}
            >
                <Radio.Group>
                    <Radio value="BUYER">Buyer</Radio>
                    <Radio value="RENTER">Renter</Radio>
                    <Radio value="INVESTOR">Investor</Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item
                name= "starting_date"
                label="Starting Date"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <DatePicker/>
            </Form.Item>

            <Form.Item name="city" label="City" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item name="neighborhood" label="Neighborhood">
                <Input />
            </Form.Item>
            <Form.Item name="property_type" label="Property Type">
                <Input />
            </Form.Item>
            <Form.Item name="number_of_bedrooms" label="Bedrooms">
                <InputNumber min={0} />
            </Form.Item>
            <Form.Item name="number_of_bathrooms" label="Baths">
                <InputNumber min={0} />
            </Form.Item>
            <Form.Item name="price_range" label="Price Range" rules={[{ required: true }]}>
                <Row gutter={8}>
                    <Col span={11}>
                        <Form.Item name= "min_price" noStyle>
                            <InputNumber min={0} placeholder="Min" style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={2} style={{ textAlign: 'center' }}>
                        -
                    </Col>
                    <Col span={11}>
                        <Form.Item name="max_price" noStyle>
                            <InputNumber min={0} placeholder="Max" style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form.Item>
            <Form.Item name="property_details" label="Property Details">
                <Input />
            </Form.Item>
            <Form.Item name="specific_property" label="Specific Property (If applicable)" extra="Please include unit number if applicable.">
                <Input />
            </Form.Item>
            <Form.Item name="business_opportunity" label="Business Opportunity (If applicable)">
                <Input />
            </Form.Item>
            <Form.Item name="notes" label="Notes">
                <Input />
            </Form.Item>
            <Form.Item
                wrapperCol={{
                    ...formLayout.wrapperCol,
                    offset: 40,
                }}
            >
                <Checkbox
                    checked={formDisable}
                    onChange={(e) => setFormDisable(e.target.checked)}
                >
                    Initial Consultation Complete
                </Checkbox>
            </Form.Item>
            <Form.Item
                wrapperCol={{
                    ...formLayout.wrapperCol,
                    offset: 40,
                }}
            >
                {formDisable ? (
                    <Button type="primary" htmlType="submit" >
                        Submit
                    </Button>
                ) : (
                    <Button type="primary" htmlType="submit" disabled>
                        Submit
                    </Button>
                )}
            </Form.Item>
        </Form>
    );
};

export default AreaOfInterestForm;
