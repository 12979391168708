import React,{useMemo} from 'react';
import {Table, Tag, Space} from 'antd';
import { useNavigate } from "react-router-dom";
import useTableSearch from "../Utils/useTableSearch";

const onChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
};


function CancelledDealTable({deals}) {
    const role = localStorage.getItem("agent_role");
    const navigate = useNavigate();
    const { getColumnSearchProps } = useTableSearch();

    const columns = useMemo(() => [
        // Conditionally add an office column if ADMIN
        ...(role === "ADMIN"
            ? [
                {
                    title: "Office",
                    dataIndex: "office_name",
                    key: "office_name",
                    width: "10%",
                    sortDirections: ['descend', 'ascend'],
                    ...getColumnSearchProps('office_name'),
                },
            ]
            : []),
        ...(role ===  "AGENT"
                ? [] :[{
                    title: "Agent Name",
                    dataIndex: "agent_name",
                    key: "agent_name",
                    width: "12%",
                    sorter: (a, b) => a.agent_name.localeCompare(b.agent_name),
                    sortDirections: ['descend', 'ascend'],
                    ...getColumnSearchProps('agent_name'),
                }]
        ),
        {
            title: "Customer Name",
            dataIndex: "client_name",
            key: "client_name",
            width: "13%",
            sorter: (a, b) => a.client_name.localeCompare(b.client_name),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('client_name'),
        },
        {
            title: 'Client Type',
            dataIndex: 'client_type',
            filters: [
                { text: 'RENTER', value: 'RENTER' },
                { text: 'BUYER', value: 'BUYER' },
                { text: 'INVESTOR', value: 'INVESTOR' },
                { text: 'LANDLORD', value: 'LANDLORD' },
                { text: 'SELLER', value: 'SELLER' },
            ],
            width: '10%',
            onFilter: (value, record) => record.client_type.startsWith(value),
            render: (_, {client_type}) => {
                const typeColorMapping = {
                    RENTER: 'blue',
                    BUYER: 'blue',
                    INVESTOR: 'blue',
                    LANDLORD: 'blue',
                    SELLER: 'blue'
                };

                const tagColor = typeColorMapping[client_type] || 'geekblue';
                return (
                    <Tag color={tagColor}>
                        {client_type}
                    </Tag>
                );
            }
        },
        {
            title: 'Starting Date',
            dataIndex: 'starting_date',
            key: 'starting_date',
            width: '12%',
            sorter: (a, b) => new Date(a.starting_date) - new Date(b.starting_date),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Inactive Date',
            dataIndex: 'date_of_inactivity',
            key: 'date_of_inactivity',
            width: '12%',
            sorter: (a, b) => new Date(a.date_of_inactivity) - new Date(b.date_of_inactivity),
            sortDirections: ['descend', 'ascend'],
        },
        ...(role ===  "ADMIN"
                ? [] :[{
                    title: 'Last Progress Step',
                    dataIndex: 'last_progress_step',
                    key: 'last_progress_step',
                    width: '12%',
                }]
        ),
        {
            title: 'Property Address / Area of Interest',
            dataIndex: 'property_address',
            key: 'property_address',
            width: '25%',
            ...getColumnSearchProps('property_address'),
        },
        {
            title: 'Action',
            key: 'action',
            width: '6%',
            render: (_, record) => (
                <Space size="middle">
                    <a
                        href={`/cancelled_deal_detail/${record.id}`}
                        onClick={(e) => {
                            e.preventDefault();
                            navigate(`/cancelled_deal_detail/${record.id}`, {
                                state: {
                                    officeName: record.office_name,
                                    agentName: record.agent_name,
                                    clientName: record.client_name,
                                },
                            });
                        }}
                    >Detail</a>
                </Space>
            ),
        },
    ], [navigate, getColumnSearchProps, role]);

    return (
        <>
            {
                deals && deals.length ? <Table columns={columns} dataSource={deals} onChange={onChange}/> :
                    <Table columns={columns} dataSource={[]} onChange={onChange}/>
            }
        </>
    )
}

export default React.memo(CancelledDealTable);