import React, { useState } from 'react';
import { Button, Timeline, notification, Modal, Form, Input, DatePicker } from 'antd';
import "./DealDetail.css";
import {
    archivedAreaOfInterest,
    archivedSubjectOfProperty,
    cancelAreaOfInterest,
    cancelSubjectOfProperty
} from "../Utils/utils";
import { useNavigate } from "react-router-dom";

function DealStep({ steps = [], currentStep, dealData, type= "SOP" }) {
    const navigate = useNavigate();
    const [cancelModalVisible, setCancelModalVisible] = useState(false);
    const isLast = currentStep === steps.length;
    const isClosed = dealData.status === "CLOSED";

    const openNotification = (description, title) => {
        notification.open({
            duration: 25,
            message: title,
            description: description,
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
    };
    const handleCancelDealSubmit = async (values) => {
        try {
            const cancelledDeal = type === "SOP" ? await cancelSubjectOfProperty(dealData.id, values) : await cancelAreaOfInterest(dealData.id, values);
            console.log("Cancelled Deal created:", cancelledDeal);
            // Navigate back to client page
            navigate(`/client_detail/${dealData.clientId}`);
        } catch (error) {
            console.error(error);
            openNotification("Unable to mark deal as cancelled", "Error");
        }
    };

    const handleArchiveDeal = async () => {
        try {
            const archivedDeal = type === "SOP" ? await archivedSubjectOfProperty(dealData.id) : await archivedAreaOfInterest(dealData.id);
            console.log("Archived Deal created:", archivedDeal);
            // Navigate back to client page
            navigate(`/client_detail/${dealData.clientId}`);
        } catch (error) {
            console.error(error);
            openNotification("Unable to archive deal", "Error");
        }
    };

    return (
        <>
            <div style={{ maxHeight: '600px', overflowY: 'auto', paddingRight: '10px' }}>
                <Timeline mode="left">
                    {steps.map((item, index) => {
                        const isCurrent = currentStep - 1 === index;
                        const isPast = index < currentStep - 1;
                        return (
                            <Timeline.Item key={index} color={getColor(item.status)}>
                                <div className={isCurrent ? 'current-step-container' : ''}>
                                    {/* Display status as a label */}
                                    <div className="status-label">
                                        {item.status.toUpperCase()}
                                    </div>
                                    <div>
                                        {isCurrent && <span className="arrow-indicator">→ </span>}
                                        <span
                                            className={
                                                isCurrent
                                                    ? 'current-step-text'
                                                    : isPast
                                                        ? 'past-step-text'
                                                        : ''
                                            }
                                        >
                                        {item.step}
                                    </span>
                                    </div>
                                </div>
                            </Timeline.Item>
                        );
                    })}
                </Timeline>
            </div>

            {/* Cancelled Button and Archived Button*/}
            {isClosed?"":<Button onClick={()=>setCancelModalVisible(true)} type="primary" style={{ marginTop: "20px"}}>Cancel</Button>}
            {isLast? <Button onClick={handleArchiveDeal} style={{marginLeft:"20px",  marginTop: "20px"}} type="primary" >Archive</Button>:""}

            {/* Cancel Modal */}
            <Modal
                title="Cancel Transaction"
                onCancel={() => setCancelModalVisible(false)}
                footer={null}
                open={cancelModalVisible}
            >
                <Form layout="vertical" onFinish={handleCancelDealSubmit}>
                    <Form.Item name="reason" label="Reason" rules={[{ required: true, message: 'Please provide a reason!' }]}>
                        <Input.TextArea rows={4}/>
                    </Form.Item>
                    <Form.Item name="date_of_inactivity" label="Date of Inactivity" rules={[{ required: true, message: 'Please provide date of inactivity!' }]}>
                        <DatePicker format="YYYY-MM-DD"/>
                    </Form.Item>

                    <Button type="primary" htmlType="submit">
                        Confirm
                    </Button>
                </Form>
            </Modal>

        </>
    );
}

function getColor(status) {
    switch (status) {
        case 'preliminary':
            return 'green';
        case 'active':
            return '#027148';
        case 'contingent':
            return 'red';
        case 'pending':
            return 'grey';
        case 'closed':
            return 'black';
        default:
            return 'blue';
    }
}

export default DealStep;