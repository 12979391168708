import React, {useState, useEffect, useCallback, useMemo} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
    getManagerFilter,
    getAgentsByManagerId,
    getClientFullName,
    getManagerByOfficeId,
    navigateTo
} from "../../Utils/utils";
import {Divider, Space, Typography, Table, Spin, Form, Tabs, notification} from "antd";
import useTableSearch from "../../Utils/useTableSearch";
import {LoadingOutlined} from "@ant-design/icons";
import {OfficeSelect, SearchByDetail} from "../../Utils/SearchUtil";

const {Title} = Typography;

function ManagerTable() {
    const navigate = useNavigate()
    const {id} = useParams()
    const role = localStorage.getItem('agent_role')
    const agentId = id ? id : localStorage.getItem('user_id')
    const officeNames = JSON.parse(localStorage.getItem("officeName"));
    const officeId = JSON.parse(localStorage.getItem("officeId"));
    const [form] = Form.useForm();

    const {getColumnSearchProps} = useTableSearch()
    const [managers, setManagers] = useState([])
    const [filterManagers, setFilteredManagers] = useState([])
    const [agents, setAgents] = useState({})
    const [expandedRowKeys, setExpandRowKeys] = useState([])
    const [loadingRow, setLoadingRow] = useState(null)

    // Admin
    const [selectedOfficeId, setSelectedOfficeId] = useState(null)
    const [selectedOfficeName, setSelectedOfficeName] = useState(null)
    const [isSearch, setIsSearch] = useState(false)
    const [activeTab, setActiveTab] = useState("officeSearch");

    const fetchManagers = useCallback(async () => {
        try {
            const current_id = role === "ADMIN" ? selectedOfficeId : agentId
            const res = await getManagerByOfficeId(current_id)
            const managerWithName = res.map(manager => ({
                ...manager,
                key: manager.id,
                full_name: getClientFullName(manager)
            }));

            setManagers(managerWithName)
        } catch (error) {
            console.log(error)
        }
    }, [role, selectedOfficeId, agentId])

    const fetchAgents = async (managerId) => {
        try {
            if (managerId) {
                setLoadingRow(managerId)
                const res = await getAgentsByManagerId(managerId)

                for (let i = 0; i < res.length; i++) {
                    res[i].full_name = getClientFullName(res[i])
                }

                setAgents((prev) => ({...prev, [managerId]: res}))
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoadingRow(null)
        }
    }

    const handleExpand = async (expanded, record) => {
        setExpandRowKeys((prevExpandedKeys) => {
            if (expanded) {
                return [...prevExpandedKeys, record.key];
            } else {
                return prevExpandedKeys.filter((key) => key !== record.key);
            }
        });

        if (expanded && !agents[record.id]) { // ✅ Fetch agents only if not already loaded
            await fetchAgents(record.id);
        }
    };

    const columns = useMemo(() => [
        {
            title: "Manager Name",
            dataIndex: "full_name",
            key: 'full_name',
            width: "12%",
            sorter: (a, b) => a.full_name.localeCompare(b.full_name),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('full_name'),
        },

        // Conditionally add an office column if ADMIN
        ...(role === "ADMIN" && activeTab === "specificSearch"
            ? [
                {
                    title: "Office",
                    dataIndex: "office_name",
                    key: "office_name",
                    width: "10%",
                    sortDirections: ['descend', 'ascend'],
                    ...getColumnSearchProps('office'),
                },
            ]
            : []),

        {
            title: 'Email',
            dataIndex: 'email',
            sorter: (a, b) => a.email.localeCompare(b.email),
            sortDirections: ['descend', 'ascend'],
            key: 'email',
            width: "15%",
            ...getColumnSearchProps('email'),
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            width: "10%",
            ...getColumnSearchProps('phone'),
        },
        {
            title: "Total customers",
            dataIndex: "customer_count",
            width: "10%",
            sorter: (a, b) => a.customer_count - b.customer_count,
        },
        {
            title: "Total live transactions",
            dataIndex: "transaction_count",
            width: "10%",
            sorter: (a, b) => a.transaction_count - b.transaction_count,
        },
        {
            title: "Total pre transactions",
            dataIndex: "pre_transaction_count",
            width: "10%",
            sorter: (a, b) => a.pre_transaction_count - b.pre_transaction_count,
        },
        {
            title: "YTD Sales Volume",
            dataIndex: "ytd_sales_volume",
            width: "10%",
            sorter: (a, b) => a.ytd_sales_volume - b.ytd_sales_volume,
        },
        {
            title: "Total agents",
            dataIndex: "agent_count",
            width: "10%",
            sorter: (a, b) => a.agent_count - b.agent_count,
        },
        {
            title: 'Action',
            key: 'action',
            width: "10%",
            render: (_, record) => (
                <Space size="middle">
                    <a href={"/"}
                       onClick={(e) => {
                           e.preventDefault();
                           const path = `/managers/${record.id}`
                           navigateTo(navigate, path, record)
                       }}>Manager Detail</a>

                </Space>
            ),
        }

    ], [role, activeTab, getColumnSearchProps, navigate])

    const expandColumns = [
        {
            title: "Agent Name",
            dataIndex: "full_name",
            key: "full_name",
            width: '15%',
            sorter: (a, b) => a.full_name.localeCompare(b.full_name),
            ...getColumnSearchProps("full_name"),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            sorter: (a, b) => a.email.localeCompare(b.email),
            sortDirections: ['descend', 'ascend'],
            key: 'email',
            width: "15%",
            ...getColumnSearchProps('email'),
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            width: "10%",
            ...getColumnSearchProps('phone'),
        },
        {
            title: "Total customers",
            dataIndex: "customer_count",
            width: "10%",
            sorter: (a, b) => a.customer_count - b.customer_count,
        },
        {
            title: "Total live transactions",
            dataIndex: "transaction_count",
            width: "10%",
            sorter: (a, b) => a.transaction_count - b.transaction_count,
        },
        {
            title: "Total pre transactions",
            dataIndex: "pre_transaction_count",
            width: "10%",
            sorter: (a, b) => a.pre_transaction_count - b.pre_transaction_count,
        },
        {
            title: "YTD Sales Volume",
            dataIndex: "ytd_sales_volume",
            width: "10%",
            sorter: (a, b) => a.ytd_sales_volume - b.ytd_sales_volume,
        },
        {
            title: "Highest Stagnant Day",
            dataIndex: "highest_stagnant_day",
            width: "10%",
            // Replace the direct numeric comparison with a numeric placeholder
            sorter: (a, b) => {
                const aVal = a.transaction_count === 0 ? -1 : a.highest_stagnant_day;
                const bVal = b.transaction_count === 0 ? -1 : b.highest_stagnant_day;
                return aVal - bVal;
            },
            // display "N/A" in the UI if transaction_count === 0
            render: (_, record) => record.transaction_count === 0
                ? "N/A"
                : record.highest_stagnant_day,
        },
        {
            title: 'Action',
            key: 'action',
            width: "10%",
            render: (_, record) => (
                <Space size="middle">
                    <a
                        href={"/"}
                        onClick={(e) => {
                            e.preventDefault()
                            const path = `/agents/${record.agent_id}`
                            navigateTo(navigate, path, record)
                        }}>Agent Detail</a>
                </Space>
            ),
        }
    ]

    const expandedRowRender = (record) => {
        if (loadingRow === record.d) {
            return <Spin indicator={<LoadingOutlined spin/>}/>;
        }

        return <Table columns={expandColumns}
                      dataSource={agents[record.id]?.map(agent => ({
                          ...agent,
                          key: agent.agent_id
                      }))}
                      pagination={false}/>
    }

    const onChange = (val) => {
        const officeIndex = officeNames.indexOf(val);
        setSelectedOfficeName(val)
        if (officeIndex !== -1) {
            setSelectedOfficeId(officeId[officeIndex]);
        }
    }

    const onSearch = async (val) => {
        let {email, name, phone} = val;
        email = email?.trim();
        name = name?.trim();
        phone = phone?.trim();

        if (!email && !name && !phone) {
            setFilteredManagers([]);
            setIsSearch(false);
            return;
        }

        try {
            let res = await getManagerFilter(name, email, phone);
            const managerWithName = res.map(manager => ({
                ...manager,
                key: manager.id,
                full_name: getClientFullName(manager)
            }));

            setFilteredManagers(managerWithName)
            setIsSearch(true);
        } catch (error) {
            notification.error(error.message)
        }
    }

    const adminAgent = () => {
        return <>
            <Tabs defaultActiveKey="officeSearch" onChange={(key) => setActiveTab(key)}>
                <Tabs.TabPane tab="Search By Office" key="officeSearch">
                    <OfficeSelect officeNames={officeNames} onChange={onChange}/>
                    {selectedOfficeName && (
                        <div style={{marginTop: "3%"}}>
                            <Divider orientation="left">Manager Table of Office {selectedOfficeName}</Divider>
                            {managers && (
                                <Table style={{marginTop: "2%"}}
                                       columns={columns}
                                       expandable={{
                                           expandedRowRender,
                                           expandedRowKeys,
                                           onExpand: handleExpand,
                                       }}
                                       dataSource={managers}
                                />
                            )}

                        </div>
                    )}

                </Tabs.TabPane>
                <Tabs.TabPane
                    tab="Search By Specific Person"
                    key="specificSearch"
                >
                    <SearchByDetail onSearch={onSearch} form={form}/>
                    <div style={{marginTop: "3%"}}>
                        <Divider orientation="left">Search Result</Divider>
                        {
                            isSearch && (
                                <>
                                    {filterManagers.length > 0 ? (
                                        <Table style={{marginTop: "2%"}}
                                               columns={columns}
                                               expandable={{
                                                   expandedRowRender,
                                                   expandedRowKeys,
                                                   onExpand: handleExpand,
                                               }}
                                               dataSource={filterManagers}
                                        />
                                    ) : (
                                        <Title level={3}> No Agent found ! </Title>
                                    )}
                                </>
                            )
                        }

                    </div>
                </Tabs.TabPane>
            </Tabs>
        </>
    }

    const generalRoleManager = () => {
        return <>
            <Title>Total Managers: {managers.length}</Title>
            <Divider orientation="left" style={{marginTop: "50px"}}>Managers Table</Divider>
            <Table
                columns={columns}
                expandable={{
                    expandedRowRender,
                    expandedRowKeys,
                    onExpand: handleExpand,
                }}
                dataSource={managers}
            />
        </>
    }

    useEffect(() => {
        if (!selectedOfficeId && role === "ADMIN") return;
        fetchManagers();
    }, [fetchManagers, selectedOfficeId, role]);


    return (<>
        {role === "ADMIN" ? adminAgent() : generalRoleManager()}
    </>)
}

export default ManagerTable;