import {BrowserRouter, Routes, Route} from "react-router-dom";
import Home from "./components/HomePage/Home";
import ClientRegistrationForm from "./components/Forms/ClientRegistrationForm";
import "./App.css";
import ClientDetail from "./components/ClientDetail/ClientDetail";
import AddTransaction from "./components/Forms/AddTransaction";
import BasicLayout from "./components/Layout/BasicLayout";
import {AuthProvider} from "./components/Utils/AuthContext";
import AreaOfInterestDeal from "./components/DealDetail/AreaOfInterestDeal";
import SubjectPropertyDeal from "./components/DealDetail/SubjectPropertyDeal";
import Profile from "./components/Profile/Profile";
import CancelledDealDetail from "./components/DealDetail/CancelledDealDetail";
import ArchivedDealDetail from "./components/DealDetail/ArchivedDealDetail";
import Agent from "./components/Agents/Agent";
import Manager from "./components/Agents/Manager";
import CustomerTable from "./components/Agents/Tables/CustomerTable/CustomerTable";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import AgentTable from "./components/Agents/Tables/AgentTable";
import LiveTransactionTable from "./components/DealTable/LiveTransactionTable";
import CancelledDealTable from "./components/DealTable/CancelledDealTable";
import ArchivedDealTable from "./components/DealTable/ArchivedDealTable";
import HistoryFilter from "./components/Agents/Tables/HistoryFilter";
import ManagerTable from "./components/Agents/Tables/ManagerTable";
import Office from "./components/Agents/Office";
import OfficeTable from "./components/Agents/Tables/OfficeTable";


function App() {
    const queryClient = new QueryClient();

    return (
        <>
            <BrowserRouter>
                <QueryClientProvider client={queryClient}>
                    <AuthProvider>
                        <Routes>
                            <Route path="/" element={<BasicLayout/>}>
                                <Route index element={<Home/>}/>
                                <Route path="client_register" element={<ClientRegistrationForm/>}/>
                                <Route path="client_detail/:id" element={<ClientDetail/>}/>
                                <Route path="add_transaction" element={<AddTransaction/>}/>
                                <Route path="aoi_deal_detail" element={<AreaOfInterestDeal/>}/>
                                <Route path="sop_deal_detail" element={<SubjectPropertyDeal/>}/>
                                <Route path="profile" element={<Profile/>}/>
                                <Route path="/cancelled_deal_detail/:dealId" element={<CancelledDealDetail />} />
                                <Route path="/archived_deal_detail/:dealId" element={<ArchivedDealDetail/>}/>
                                <Route path="/agents/:id" element={<Agent/>}/>
                                <Route path="/managers/:id" element={<Manager/>}/>
                                <Route path="/offices/:id" element={<Office/>}/>
                                <Route path="/clients/:role/:id" element={<CustomerTable/>}/>
                                <Route path="/all_agents/:role/:id" element={<AgentTable/>}/>
                                <Route path="/all_managers/:id" element={<ManagerTable/>}/>
                                <Route path="/all_offices" element={<OfficeTable/>}/>
                                <Route path="/live_transactions/:role/:id" element={<LiveTransactionTable/>}/>
                                <Route path="/cancelled_transactions/:id" element={<CancelledDealTable />} />
                                <Route path="/archived_transactions/:id" element={<ArchivedDealTable/>}/>
                                <Route path="/history_table/:role/:id/:type" element={<HistoryFilter/>}/>
                            </Route>
                        </Routes>
                    </AuthProvider>
                </QueryClientProvider>
            </BrowserRouter>
        </>
    )
}

export default App;