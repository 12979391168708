import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Typography, Button, Row, Col, Divider } from 'antd';
import { readableNumberFormat } from "../Utils/utils";
import axiosInstance from "../Utils/axiosInstance";

const { Title } = Typography;

function ArchivedDealDetail() {
    const { dealId } = useParams();         // Read the route param
    const navigate = useNavigate();
    const { state } = useLocation();
    const { officeName, agentName, clientName } = state || {};
    const [archivedDeal, setArchivedDeal] = useState(null);

    // Fetch the archived deal by ID
    useEffect(() => {
        const fetchArchivedDeal = async () => {
            const url = `/archived-deals/get-by-id/${dealId}`;
            try{
                const response = await axiosInstance.get(url)
                setArchivedDeal(response.data);
                return response.data;
            } catch (error) {
                const status = error.response?.status;

                if(status === 401){
                    window.location.href = "/";
                    localStorage.clear()
                    return
                }
                const message = error.response?.data || error.message;
                throw new Error(`HTTP error! status: ${status}, message: ${message}`);
            }
        };
        fetchArchivedDeal();
    }, [dealId]);

    if (!archivedDeal) {
        return (
            <div style={{ padding: '20px' }}>
                <Button type="default" onClick={() => navigate(-1)}>Back</Button>
                <Divider/>
                <div>No Archived Deal Data Available</div>
            </div>
        );
    }

    return (
        <div style={{ padding: '20px' }}>
            <Button type="default" onClick={() => navigate(-1)}>Back</Button>
            <Divider/>
            <Title level={2}>Completed Transaction:</Title>

            {officeName && <h3>Office Name: {officeName}</h3>}
            {agentName && <h3>Agent Name: {agentName}</h3>}
            {clientName && <h3>Client Name: {clientName}</h3>}

            <Row gutter={[16,16]} style={{ marginTop: "10px" }}>
                <Col data-testid={"client-type"} span={6}>
                    <h3>Client Type:</h3>
                    <p>{archivedDeal.clientType}</p>
                </Col>
                <Col data-testid={"property-address"} span={6}>
                    <h3>Property Address:</h3>
                    <p>{archivedDeal.propertyAddress}</p>
                </Col>
            </Row>

            <Row gutter={[16,16]} style={{ marginTop: "10px" }}>
                <Col span={6}>
                    <h3>Starting Date:</h3>
                    <p>{archivedDeal.startingDate}</p>
                </Col>
                <Col span={6}>
                    <h3>Client Representation Date:</h3>
                    <p>{archivedDeal.clientRepresentationDate}</p>
                </Col>
            </Row>

            <Row gutter={[16,16]} style={{ marginTop: "10px" }}>
                <Col span={6}>
                    <h3>Closing Date:</h3>
                    <p>{archivedDeal.closingDate}</p>
                </Col>
                <Col span={6}>
                    <h3>Closing Price:</h3>
                    <p>{readableNumberFormat(archivedDeal.closingPrice)}</p>
                </Col>
            </Row>

            <Row gutter={[16,16]} style={{ marginTop: "10px" }}>
                <Col span={24}>
                    <h3>Note History:</h3>
                    <p>{archivedDeal.notes || "N/A"}</p>
                </Col>
            </Row>
        </div>
    );
}

export default ArchivedDealDetail;