import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Typography, Button, Row, Col, Divider } from 'antd';
import { allSteps } from "../Utils/utils";
import axiosInstance from "../Utils/axiosInstance";
const { Title } = Typography;

function CancelledDealDetail() {
    const { dealId } = useParams(); // read the route param
    const navigate = useNavigate();
    const { state } = useLocation();
    const { officeName, agentName, clientName } = state || {};
    const [cancelledDeal, setCancelledDeal] = useState(null);

    // Fetch the cancelled deal by ID
    useEffect(() => {
        const fetchCancelledDeal = async () => {
            const url = `/cancelled-deals/get-by-id/${dealId}`
            try{
                const response = await axiosInstance.get(url)
                setCancelledDeal(response.data);
                return response.data;
            } catch (error) {
                const status = error.response?.status;

                if(status === 401){
                    window.location.href = "/";
                    localStorage.clear()
                    return
                }
                const message = error.response?.data || error.message;
                throw new Error(`HTTP error! status: ${status}, message: ${message}`);
            }
        };
        fetchCancelledDeal();
    }, [dealId]);

    if (!cancelledDeal) {
        return (
            <div style={{ padding: '20px' }}>
                <Button type="default" onClick={() => navigate(-1)}>Back</Button>
                <Divider/>
                <div>No Cancelled Deal Data Available</div>
            </div>
        );
    }

    // Get the steps array for the clientType
    const stepsArray = allSteps[cancelledDeal.clientType] || [];

    // Convert the numeric step to descriptive text
    let lastStepDescription = "N/A";
    if (
        cancelledDeal.lastProgressStep &&
        cancelledDeal.lastProgressStep <= stepsArray.length
    ) {
        const index = cancelledDeal.lastProgressStep - 1;
        lastStepDescription = stepsArray[index]?.step || "N/A";
    }

    return (
        <div style={{ padding: '20px' }}>
            <Button type="default" onClick={() => navigate(-1)}>Back</Button>
            <Divider/>
            <Title level={2}>Cancelled Transaction:</Title>

            {officeName && <h3>Office Name: {officeName}</h3>}
            {agentName && <h3>Agent Name: {agentName}</h3>}
            {clientName && <h3>Client / Customer Name: {clientName}</h3>}

            <Row gutter={[16,16]} style={{ marginTop: "10px" }}>
                <Col span={6}>
                    <h3>Client Type:</h3>
                    <p>{cancelledDeal.clientType}</p>
                </Col>
                <Col span={6}>
                    <h3>Property Address / Area of Interest:</h3>
                    <p>{cancelledDeal.propertyAddress}</p>
                </Col>
            </Row>

            <Row gutter={[16, 16]} style={{marginTop: "10px"}}>
                <Col span={6}>
                    <h3>Starting Date:</h3>
                    <p>{cancelledDeal.startingDate}</p>
                </Col>
                <Col span={6}>
                    <h3>Client Representation Date:</h3>
                    <p>{cancelledDeal.clientRepresentationDate || "N/A"}</p>
                </Col>
            </Row>

            <Row gutter={[16, 16]} style={{marginTop: "10px"}}>
                <Col span={6}>
                    <h3>Status Upon Cancellation:</h3>
                    <p>{cancelledDeal.statusUponCancellation}</p>
                </Col>
                <Col span={6}>
                    <h3>Inactive Date:</h3>
                    <p>{cancelledDeal.dateOfInactivity}</p>
                </Col>
            </Row>

            <Row gutter={[16, 16]} style={{marginTop: "10px"}}>
                <Col span={24}>
                    <h3>Last Progress Step:</h3>
                    <p>{lastStepDescription}</p>
                </Col>
            </Row>

            <Row gutter={[16, 16]} style={{marginTop: "10px"}}>
            <Col span={24}>
                    <h3>Reason:</h3>
                    <p>{cancelledDeal.reason}</p>
                </Col>
            </Row>

            <Row gutter={[16,16]} style={{ marginTop: "10px" }}>
                <Col span={24}>
                    <h3>Note History:</h3>
                    <p>{cancelledDeal.notes || "N/A"}</p>
                </Col>
            </Row>
        </div>
    );
}

export default CancelledDealDetail;