import {Row, Divider} from "antd";
import React, {useState, useEffect} from "react";
import {useNavigate, useLocation, useParams} from "react-router-dom";
import {
    getAgentById,
    getClientFullName,
    generateSummaryRow,
    navigateTo, welcomeStatement
} from "../Utils/utils";
import "./Agent.css"
import LiveTransactionTable from "../DealTable/LiveTransactionTable";
import ActionList from "../Utils/ActionList";

const keys =["customer_count", "transaction_count", "pre_transaction_count", "ytd_sales_volume"];
const contents = ["Total Customers", "Total Live Transactions", "Total Preliminary Transactions", "YTD Sales Vol"];

function Agent() {
    const agentAccess = localStorage.getItem("agent_role") === "AGENT";

    // Extract  agent info when higher level access
    const location = useLocation();
    const agent = location.state;

    const {id} = useParams()
    const agentId = agentAccess ? localStorage.getItem("userId") : id

    const fullName = agentAccess ? localStorage.getItem("full_name") : getClientFullName(agent)
    const email = agentAccess ?  localStorage.getItem("email") : agent.email

    // Navigate to client detail page
    const navigate = useNavigate();
    const [agentInfo, setAgentInfo] = useState(agent);

    // conditional get the agent info

    const actionHandlers = [
        () => navigateTo(navigate, "/client_register", { id: agentId, fullName }), // Wrapped in arrow function
        () => navigateTo(navigate, `/clients/AGENT/${agentId}`)
    ];

    useEffect(() => {
        if (agentAccess && !agentInfo) {
            (async () => {
                try {
                    const fetchedAgent = await getAgentById(agentId);
                    setAgentInfo(fetchedAgent);
                } catch (error) {
                    alert(error);
                }
            })();
        }
    }, [agentAccess, agentId, agentInfo]);

    return (
        <>
            {
                agentAccess ? welcomeStatement(fullName, "Agent") : welcomeStatement(fullName,"Agent", true, email)
            }

            <ActionList roleType="MANAGER" actionHandlers={actionHandlers}/>
            {agentInfo ? <Row className="row-style" justify="space-evenly">
                {generateSummaryRow(agentInfo, keys, contents)}
            </Row> : null}
            <Divider orientation="left" className="divider">Live Transactions</Divider>
            <LiveTransactionTable agentId = {agentId} agentRole="AGENT"/>
        </>
    )
}

export default Agent;