import axios from 'axios';

const generateHeader = () =>{
    const token = localStorage.getItem("jwt_token");
    const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
    };
    return headers
}

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_REMOTE_SERVER + "/api",
});

axiosInstance.interceptors.request.use(
    (config) => {
        const headers = generateHeader();
        config.headers = {
            ...config.headers, // preserve existing headers if any
            ...headers
        };
        return config;
    },
    (error) => Promise.reject(error)
);

// Response interceptor to catch all errors globally
axiosInstance.interceptors.response.use(
    response => response, // Return response directly if successful
    error => {
        const status = error.response?.status;
        const errorType = error.response?.data.error;
        // handle 401
        if (status === 401 ) {
            // login problem: password and email wrong
            if ( errorType === "LOGIN_FAILED"){
                throw error;
            } else {
                // token expired
                localStorage.clear();
                window.location.href = "/";

                // Show error message
                alert(error.response?.data?.message);

                // Delay redirect so the user can see the message
                setTimeout(() => {
                    localStorage.clear();
                    window.location.href = "/";
                }, 1000); // 1-second delay
            }
            throw new Error(`HTTP error! status: ${status}, message: ${error.response?.data?.message}`);
        }

        // For other errors, return error
        throw error;
    }
);


export default axiosInstance;