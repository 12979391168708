import React, {useCallback, useEffect, useState, useMemo} from 'react';
import {
    Space,
    Spin,
    Table,
    Tag,
    Typography,
    Divider,
    Modal,
    Button,
    Select,
    notification,
    Tabs,
    Form
} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import useTableSearch from "../../Utils/useTableSearch";
import {
    assignAgentToManager,
    dealNavigate, getAgentByOfficeId, getAgentFilter,
    getAgentsByManagerId,
    getClientFullName, getDaysStagnant, getLiveTransactions, getManagerByOfficeId, getManagerNameByOfficeId, navigateTo,
} from "../../Utils/utils";
import {useNavigate, useParams} from "react-router-dom";
import {clientTypeFilters, statusFilter, checkDealType, typeColorMapping} from "../../DealTable/LiveTransactionTable";
import "../Agent.css"
import {OfficeSelect, SearchByDetail} from "../../Utils/SearchUtil";

const {Title} = Typography;

const getAgentOption = (agents) => {
    return agents.filter(person => person.agent_role === "AGENT")
        .map(person => ({
            label: getClientFullName(person),
            value: person.agent_id,
            id: person.agent_id
        }));
}

function AgentTable() {
    const navigate = useNavigate()
    let {id, role} = useParams()
    role = role ? role : localStorage.getItem("agent_role");
    const agentId = id ? id : localStorage.getItem("user_id")
    const officeNames = JSON.parse(localStorage.getItem("officeName"));
    const officeId = JSON.parse(localStorage.getItem("officeId"));


    const [form] = Form.useForm();
    const {getColumnSearchProps} = useTableSearch()


    const [agents, setAgent] = useState([])
    const [transactions, setTransactions] = useState({})
    const [managerOption, setManagerOption] = useState([])
    const [agentOption, setAgentOption] = useState([])


    const [expandedRowKeys, setExpandedRowKeys] = useState([]); // Track the expand row
    const [loadingRow, setLoadingRow] = useState(null); // Track loading for specific rows


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectManager, setSelectManager] = useState(null);
    const [selectAgent, setSelectedAgent] = useState([])
    const [selectedRole, setSelectedRole] = useState(null);
    const [selectName, setSelectName] = useState(null)


    const [selectedOfficeId, setSelectedOfficeId] = useState(null)
    const [selectedOfficeName, setSelectedOfficeName] = useState(null)
    const [isSearch, setIsSearch] = useState(false)
    const [activeTab, setActiveTab] = useState("officeSearch");
    const [filteredAgent, setFilteredAgent] = useState([]);

    const openErrorNotification = (message, description) => {
        notification.error({
            message,
            description,
            duration: 4, // Show for 4 seconds
        });
    };
    const openSuccessNotification = (message,description) => {
        notification.success({
            message: message || "Success",
            description: description || "Agent created successfully!",
            duration: 3,
        });
    };
    const navigateToDealDetail = (dealId, deal) => {
        const type = checkDealType(deal.client_type)
        const url = dealNavigate(type)
        navigate(url, {
            state: {
                dealId,
                deal
            },
        });
    };

    const fetchAgents = useCallback(async () => {
        try {
            let res;
            if (role === "MANAGER") {
                res = await getAgentsByManagerId(agentId);
            } else if (role === "OFFICE") {
                res = await getAgentByOfficeId(agentId);
                const selectOption = getAgentOption(res)
                setAgentOption([...selectOption]);
            } else {
                // role == admin
                res = await getAgentByOfficeId(selectedOfficeId);
                const selectOption = getAgentOption(res)
                setAgentOption([...selectOption]);
            }

            const processedAgent = res.map(agent => ({
                ...agent,
                key: agent.agent_id,
                full_name: getClientFullName(agent),
            }))

            setAgent([...processedAgent]);

        } catch (error) {
            openErrorNotification("Can't fetch agent", error.message);
        }
    }, [role, agentId, selectedOfficeId])

    const fetchTransaction = useCallback(async (agentId) => {
        try {
            if (agentId) {
                try {
                    setLoadingRow(agentId);
                    const res = await getLiveTransactions(agentId, "AGENT")
                    setTransactions((prev) => ({...prev, [agentId]: res}));
                } catch (e) {
                    console.log(e)
                } finally {
                    setLoadingRow(null);
                }
            }
        } catch (error) {
            notification.error("Fail to get transactions")
        }
    }, [])

    const fetchManagerOption = useCallback(async () => {
        try {
            let res = [];

            if (role === "OFFICE") {
                res = await getManagerNameByOfficeId(agentId)
            }

            if (role === "ADMIN") {
                res = await getManagerByOfficeId(selectedOfficeId)
            }

            const transformedManagers = res.map(person => {
                return {
                    label: getClientFullName(person),  // Ensure this function is being called
                    value: person.id,
                    id: person.id
                };
            });

            setManagerOption(transformedManagers);


        } catch (error) {
            console.log("Cannot get the manager name by agent id")
        }
    }, [role, agentId, selectedOfficeId])

    const handleExpand = async (expanded, record) => {
        setExpandedRowKeys((prevExpandedKeys) => {
            if (expanded) {
                return [...prevExpandedKeys, record.key];
            } else {
                return prevExpandedKeys.filter((key) => key !== record.key);
            }
        });

        if (expanded && !transactions[record.agent_id]) {
            await fetchTransaction(record.agent_id);
        }
    };

    const handleAssignManager = async () => {
        try {
            await assignAgentToManager(selectAgent, selectManager);
            setIsModalOpen(false)
            setSelectedAgent([])
            setSelectManager(null)
            setSelectedRole(null)
            openSuccessNotification("Success", "Agent assigned successfully!");

        } catch (error) {
            openErrorNotification("Fail to assign manager to agent", error.message || "Something went wrong")
        }
    }

    const handleCancel = () => {
        setIsModalOpen(false);
        setSelectedAgent([]);
        setSelectManager(null);
        setSelectedRole(null);
    };

    const columns = useMemo(() => [
        {
            title: "Agent Name",
            dataIndex: "full_name",
            key: 'full_name',
            width: "12%",
            sorter: (a, b) => a.full_name.localeCompare(b.full_name),
            sortDirections: ['descend', 'ascend'],
            ...getColumnSearchProps('full_name'),
        },
        // Conditionally add an office column if ADMIN
        ...(role === "ADMIN" && activeTab === "specificSearch"
            ? [
                {
                    title: "Office",
                    dataIndex: "office_name",
                    key: "office_name",
                    width: "10%",
                    sortDirections: ['descend', 'ascend'],
                    ...getColumnSearchProps('office'),
                },
            ]
            : []),
        // Conditionally add an agent_role column if OFFICE
        ...(role === "OFFICE" || role === "ADMIN"
            ? [
                {
                    title: "Agent Role",
                    dataIndex: "agent_role",
                    key: "agent_role",
                    width: "10%",
                    filters: [
                        {text: 'AGENT', value: 'AGENT'},
                        {text: 'MANAGER', value: 'MANAGER'},
                    ],
                    onFilter: (value, record) => record.agent_role === value,
                    render: (_, {agent_role}) => (
                        <>
                            <Tag color={agent_role === 'AGENT' ? 'blue' : 'darkblue'}>
                                {agent_role}
                            </Tag>
                        </>
                    ),
                },
            ]
            : []),
        {
            title: 'Email',
            dataIndex: 'email',
            sorter: (a, b) => a.email.localeCompare(b.email),
            sortDirections: ['descend', 'ascend'],
            key: 'email',
            width: "15%",
            ...getColumnSearchProps('email'),
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            width: "10%",
            ...getColumnSearchProps('phone'),
        },
        {
            title: "Total customers",
            dataIndex: "customer_count",
            width: "10%",
            sorter: (a, b) => a.customer_count - b.customer_count,
        },
        {
            title: "Total live transactions",
            dataIndex: "transaction_count",
            width: "10%",
            sorter: (a, b) => a.transaction_count - b.transaction_count,
        },
        {
            title: "Total pre transactions",
            dataIndex: "pre_transaction_count",
            width: "10%",
            sorter: (a, b) => a.pre_transaction_count - b.pre_transaction_count,
        },
        {
            title: "YTD Sales Volume",
            dataIndex: "ytd_sales_volume",
            width: "10%",
            sorter: (a, b) => a.ytd_sales_volume - b.ytd_sales_volume,
        },
        {
            title: "Highest Stagnant Day",
            dataIndex: "highest_stagnant_day",
            width: "10%",
            defaultSortOrder: 'descend',
            // Replace the direct numeric comparison with a numeric placeholder
            sorter: (a, b) => {
                const aVal = a.transaction_count === 0 ? -1 : a.highest_stagnant_day;
                const bVal = b.transaction_count === 0 ? -1 : b.highest_stagnant_day;
                return aVal - bVal;
            },
            // display "N/A" in the UI if transaction_count === 0
            render: (_, record) => record.transaction_count === 0
                ? "N/A"
                : record.highest_stagnant_day,
        },
        {
            title: 'Action',
            key: 'action',
            width: "15%",
            render: (_, record) => (
                <Space size="middle">
                    {/*<a onClick={() => {*/}
                    {/*    const path = `/agents/${record.agent_id}`*/}
                    {/*    navigateTo(navigate, path, record)*/}
                    {/*}}>Agent Detail</a>*/}
                    <a
                        href={`/agents/${record.agent_id}`}
                        onClick={(e) => {
                            e.preventDefault(); // Prevent default navigation
                            navigateTo(navigate, `/agents/${record.agent_id}`, record);
                        }}
                    >
                        Agent Detail
                    </a>

                    {(role === "OFFICE" || role === "ADMIN") && record.agent_role === "AGENT" && (
                        <a
                            href={"/"}
                            onClick={(e) => {
                                e.preventDefault();
                            setIsModalOpen(true)
                            setSelectedAgent([record.agent_id])
                            setSelectedRole(record.agent_role)
                            setSelectName(record.full_name)
                        }}>Assign Manager</a>
                    )}

                    {(role === "OFFICE" || role === "ADMIN") && record.agent_role === "MANAGER" && (
                        <a
                            href={"/"}
                            onClick={(e) => {
                                e.preventDefault();
                            setIsModalOpen(true)
                            setSelectManager(record.agent_id)
                            setSelectedRole(record.agent_role)
                            setSelectName(record.full_name)
                        }}>Assign Agent(s)</a>
                    )}
                </Space>
            ),
        }

    ], [activeTab, role, getColumnSearchProps, navigate])


    const expandColumns = [
        {
            title: "Customer Name",
            dataIndex: "client_name",
            key: "client_name",
            width: '15%',
            sorter: (a, b) => a.clientName.localeCompare(b.client_name),
            ...getColumnSearchProps("client_name"),
        },
        {
            title: 'Client Type',
            dataIndex: 'client_type',
            width: '10%',
            filters: clientTypeFilters,
            render: (_, {client_type}) => {
                const typeColorMapping = {
                    RENTER: 'blue',
                    BUYER: 'blue',
                    INVESTOR: 'blue',
                    LANDLORD: 'blue',
                    SELLER: 'blue'
                };

                const tagColor = typeColorMapping[client_type] || 'geekblue';
                return (
                    <Tag color={tagColor}>
                        {client_type}
                    </Tag>
                );
            },
            onFilter: (value, record) => record.client_type.startsWith(value),
        },
        {
            title: "Starting Date",
            dataIndex: "starting_date",
            key: "starting_date",
            width: "10%",
            sorter: (a, b) => new Date(a.starting_date) - new Date(b.starting_date),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: "Property Address / Area of Interest",
            dataIndex: "property_address",
            key: "property_address",
            width: "30%",
            sorter: (a, b) => a.property_address.localeCompare(b.property_address),
            ...getColumnSearchProps('property_address'),
        },
        {
            title: "Deal Status",
            dataIndex: "deal_status",
            key: "deal_status",
            width: "10%",
            filters: statusFilter,
            onFilter: (value, record) => record.deal_status.startsWith(value),
            render: (_, {deal_status}) => {
                const tagColor = typeColorMapping[deal_status] || 'geekblue';
                return (
                    <Tag color={tagColor}>
                        {deal_status}
                    </Tag>
                );
            }
        },
        {
            title: "Days Stagnant",
            dataIndex: "days_stagnant",
            key: "days_stagnant",
            width: "10%",
            sorter: (a, b) => a.days_stagnant - b.days_stagnant,
            render: (_, record) => getDaysStagnant(record.last_proceed_date),
        },
        {
            title: "Action",
            key: "action",
            width: '15%',
            render: (_, record) => (
                <Space size="middle">
                    <a  href={"/"} onClick={(e) => {
                        e.preventDefault();
                        navigateToDealDetail(record.transaction_id, record)
                    }}>Transaction Detail</a>
                </Space>
            ),
        }
    ];

    const expandedRowRender = (record) => {
        if (loadingRow === record.agent_id) {
            return <Spin indicator={<LoadingOutlined spin/>}/>;
        }

        return <Table columns={expandColumns}
                      dataSource={transactions[record.agent_id]?.map(transaction => ({
                          ...transaction,
                          key: transaction.transaction_id
                      }))}
                      pagination={false}/>
    }

    const onChange = (val) => {
        const officeIndex = officeNames.indexOf(val);
        setSelectedOfficeName(val)
        if (officeIndex !== -1) {
            setSelectedOfficeId(officeId[officeIndex]);
        }
    }

    const onSearch = async (val) => {
        let {email, name, phone} = val;
        email = email?.trim();
        name = name?.trim();
        phone = phone?.trim();

        try {
            let res = await getAgentFilter(name, email, phone);
            res = res.map(agent => ({
                ...agent,
                key: agent.agent_id,
                full_name: getClientFullName(agent),
                agent_id: agent.agent_id
            }));
            setIsSearch(true);
            setFilteredAgent(res)
        } catch (error) {
            console.error("Error fetching agents:", error);
        }
    }

    useEffect(() => {
        if (role === "MANAGER" || role === "OFFICE") {
            fetchAgents();
            fetchManagerOption();
        } else if (role === "ADMIN" && selectedOfficeId) {
            fetchAgents();
            fetchManagerOption();
        }
    }, [fetchAgents, fetchManagerOption,selectedOfficeId, role]);

    const adminAgent = () => {
        return <>
            <Tabs defaultActiveKey="officeSearch" onChange={(key) => setActiveTab(key)}>
                <Tabs.TabPane tab="Search By Office" key="officeSearch">
                    <OfficeSelect officeNames={officeNames} onChange={onChange}/>
                    {selectedOfficeName && (
                        <div style={{marginTop: "3%"}}>
                            <Divider orientation="left">Agent Table of Office {selectedOfficeName}</Divider>
                            {agents && (
                                <Table style={{marginTop: "2%"}}
                                       columns={columns}
                                       expandable={{
                                           expandedRowRender,
                                           expandedRowKeys,
                                           onExpand: handleExpand,
                                       }}
                                       dataSource={agents}
                                />
                            )}

                        </div>
                    )}

                </Tabs.TabPane>
                <Tabs.TabPane
                    tab="Search By Specific Person"
                    key="specificSearch"
                >
                    <SearchByDetail onSearch={onSearch} form={form}/>
                    <div style={{marginTop: "3%"}}>
                        <Divider orientation="left">Search Result</Divider>
                        {
                            isSearch && (
                                <>
                                    {filteredAgent.length > 0 ? (
                                        <Table style={{marginTop: "2%"}}
                                               columns={columns}
                                               expandable={{
                                                   expandedRowRender,
                                                   expandedRowKeys,
                                                   onExpand: handleExpand,
                                               }}
                                               dataSource={filteredAgent}
                                        />
                                    ) : (
                                        <Title level={3}> No Agent found ! </Title>
                                    )}
                                </>
                            )
                        }

                    </div>
                </Tabs.TabPane>
            </Tabs>
        </>
    }

    const generalRoleAgent = () => {
        return <>
            <Title>Total Agents: {agents.length}</Title>
            <Divider orientation="left" style={{marginTop: "50px"}}>Agent Table</Divider>
            <Table
                columns={columns}
                expandable={{
                    expandedRowRender,
                    expandedRowKeys,
                    onExpand: handleExpand,
                }}
                dataSource={agents}
            />
        </>
    }

    return (
        <>
            {role === "ADMIN" ? adminAgent() : generalRoleAgent()}

            {/* Add Manager or Agent Modal*/}
            <Modal
                title={selectedRole === "AGENT"
                    ? "Assign a Manager to selected Agent"
                    : "Assign Agent(s) to selected manager"
                }
                open={isModalOpen}
                onCancel={handleCancel}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleAssignManager}>
                        Submit
                    </Button>,
                ]}
                width={600}
                bodyStyle={{padding: "20px", minHeight: "200px"}}
                forceRender={true}
            >
                <div style={{display: "flex", flexDirection: "column", gap: "16px"}}>
                    {
                        selectedRole === "AGENT" ?
                            <>
                                {selectName && <h2>Current Agent: {selectName}</h2>}
                                <Select
                                    size={"large"}
                                    showSearch
                                    placeholder="Select a manager"
                                    optionFilterProp="children"
                                    onChange={(value) => setSelectManager(value)}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={managerOption}
                                    value={selectManager}
                                />
                            </> :
                            <>
                                {selectName && <h2>Current Manager: {selectName}</h2>}
                                <Select
                                    mode="multiple"
                                    size="large"
                                    allowClear
                                    showSearch
                                    placeholder="Please select agents"
                                    filterOption={(input, option) =>
                                        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                    }
                                    onChange={(value) => setSelectedAgent(value)}
                                    options={agentOption}
                                    value={selectAgent}
                                />
                            </>
                    }
                </div>
            </Modal>

        </>
    )
}

export default AgentTable;